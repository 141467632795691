import React from 'react';
import Flex from 'components/common/Flex';

import { Card, Col, Row } from 'react-bootstrap';

const DailyEmailUsage = () => {
  return (
    <Card className="h-md-100">
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Daily email usage</h5>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <Row className="col-12">
          <Col md={4}>
            <p className="mb-2">Email sent</p>
            <p className="m-0 fs-3">0</p>
          </Col>
          <Col md={4}>
            <p className="mb-2">Remaining sends</p>
            <p className="m-0 fs-3">50,000</p>
          </Col>
          <Col md={4}>
            <p className="mb-2">Sending quota used</p>
            <p className="m-0 fs-3">0.00%</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DailyEmailUsage;
