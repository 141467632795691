import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { getNotification } from 'redux/reducer/notification';
import { useAppDispatch } from 'redux/store';
import createMarkup from 'helpers/createMarkup';
import './styles.scss';

const Notification = ({
  id,
  className,
  content,
  detail,
  markedAsRead,
  createdAt
}) => {
  const dispatch = useAppDispatch();
  const formattedDifference = () => {
    const now = dayjs();
    const diffInSeconds = now.diff(createdAt, 'second');
    const diffInMinutes = now.diff(createdAt, 'minute');
    const diffInHours = now.diff(createdAt, 'hour');
    const diffInDays = now.diff(createdAt, 'day');
    const diffInWeeks = now.diff(createdAt, 'week');

    if (diffInSeconds < 60) {
      return `${diffInSeconds}s`;
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h`;
    } else if (diffInDays < 7) {
      return `${diffInDays}d`;
    } else {
      return `${diffInWeeks}w`;
    }
  };
  const handleReadNotification = async () => {
    try {
      await axiosClient.put(`/notifications/${id}`, { markedAsRead: true });
      dispatch(getNotification());
    } catch (error) {
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to mark as read');
      }
    }
  };
  return (
    <div
      className={classNames(
        'notification notification-flush',
        { 'notification-unread': !markedAsRead },
        className
      )}
      onClick={handleReadNotification}
    >
      <div className="notification-body">
        <div className="mb-1" dangerouslySetInnerHTML={createMarkup(content)} />
        {detail && (
          <p>
            <i style={{ fontSize: '12px' }}>
              Check the notification tab of the campaign or check your emails
              for more details
            </i>
          </p>
        )}
        <span className="notification-time">{formattedDifference()} ago</span>
      </div>
    </div>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  content: PropTypes.string,
  detail: PropTypes.string,
  markedAsRead: PropTypes.bool,
  createdAt: PropTypes.string
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
