import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
  Modal
} from 'react-bootstrap';

import AddCampaignModal from 'components/campaigns/AddCampaignModal';
import DeleteCampaignModal from 'components/campaigns/DeleteCampaignModal';
import UpdateCampaignModal from 'components/campaigns/UpdateCampaignModal';
import CustomPagination from 'components/pagination';
import { CampaignType, ExecutionType } from 'data/common';
import dayjs from 'dayjs';
import { AiFillDelete } from 'react-icons/ai';
import {
  FaCaretDown,
  FaCaretUp,
  FaCheckSquare,
  FaEdit,
  FaMinusSquare,
  FaRegSquare
} from 'react-icons/fa';
import { IoPlay } from 'react-icons/io5';
import { FaPlus, FaFlag } from 'react-icons/fa6';
import { MdRemoveRedEye } from 'react-icons/md';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/reducer/auth';
import { useAppSelector } from 'redux/store';
import { selectOrganization } from 'redux/reducer/auth';
import { OrganizationRole } from 'data/common';
import axiosClient from 'services/axios';
import './styles.scss';

const ASC = 'ASC';
const DESC = 'DESC';

const CampaignsPage = () => {
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const [isFetching, setIsFetching] = useState(true);

  const [campaigns, setCampaigns] = useState([]);

  const [showAddCampaignModal, setShowAddCampaignModal] = useState(false);

  const [showUpdateCampaignModal, setShowUpdateCampaignModal] = useState(false);
  const [showCampaignErrorModal, setShowCampaignErrorModal] = useState(false);
  const [updatingCampaign, setUpdatingCampaign] = useState();

  const [isViewingCampaign, setIsViewingCampaign] = useState(false);

  const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false);
  const [deletingCampaignIds, setDeletingCampaignIds] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const [sort, setSort] = useState({
    fieldName: 'createdAt',
    order: DESC
  });

  const [segments, setSegments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [emailSenders, setEmailSenders] = useState([]);
  const [textSenders, setTextSenders] = useState([]);

  const handleCloseCampaignErrorModal = () => setShowCampaignErrorModal(false);

  const fetchCampaigns = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(
        `/campaigns?page=${page}&limit=${limit}&sort=${sort.fieldName}:${sort.order}&search=${search}`
      );
      const _campaigns = response.data.campaigns.map(campaign => ({
        ...campaign,
        selected: false
      }));
      setCampaigns(_campaigns);
      setTotal(response.data.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get campaigns');
      }
    }
    setIsFetching(false);
  };

  const selectedCampaignsCount = campaigns.filter(item => item.selected).length;

  const onCampaignClick = index => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[index].selected = !updatedCampaigns[index].selected;
    setCampaigns(updatedCampaigns);
  };

  const onHeaderCheckBoxClick = () => {
    if (selectedCampaignsCount === campaigns.length) {
      setCampaigns(
        campaigns.map(campaign => ({
          ...campaign,
          selected: false
        }))
      );
    } else {
      setCampaigns(
        campaigns.map(campaign => ({
          ...campaign,
          selected: true
        }))
      );
    }
  };

  const getArrowStyles = (fieldName, order) => {
    if (sort.fieldName === fieldName && sort.order === order) {
      return {
        color: '#748194'
      };
    } else {
      return {
        color: '#d8e2ef'
      };
    }
  };

  const onSortChange = fieldName => {
    if (sort.fieldName === fieldName) {
      setSort({
        fieldName,
        order: sort.order === ASC ? DESC : ASC
      });
    } else {
      setSort({
        fieldName,
        order: ASC
      });
    }
  };

  const handleUpdateCampaign = _updatingCampaign => {
    let emailSender = '';
    let textSender = '';
    _updatingCampaign.campaignExecutions.forEach(execution => {
      execution.campaignCommunications.forEach(communication => {
        if (emailSender && textSender) return;
        const emailAccount = emailSenders.find(
          sender => sender.id === communication.senderId
        );
        if (communication.communicationType === 'Email' && emailAccount) {
          emailSender = communication.senderId;
        } else if (communication.communicationType === 'Text') {
          textSender = communication.senderId;
        }
      });
    });

    // Sort the campaignExecutions by scheduledStartDate
    const sortedExecutions = _updatingCampaign.campaignExecutions.sort((a, b) =>
      dayjs(a.scheduledStartDate).isBefore(dayjs(b.scheduledStartDate)) ? -1 : 1
    );

    if (
      _updatingCampaign.campaignType === CampaignType.RECURRING &&
      _updatingCampaign.campaignExecutions.length > 0
    ) {
      const executions = [];
      sortedExecutions.forEach(execution => {
        if (
          !executions.find(
            item =>
              item.endDate === execution.endDate &&
              item.frequency === execution.frequency &&
              JSON.stringify(item.segmentIds) ===
                JSON.stringify(execution.segmentIds) &&
              JSON.stringify(item.campaignCommunications) ===
                JSON.stringify(execution.campaignCommunications)
          )
        ) {
          executions.push(execution);
        }
      });
      _updatingCampaign.campaignExecutions = [...executions];
    }

    setUpdatingCampaign({
      ..._updatingCampaign,
      campaignExecutions: _updatingCampaign.campaignExecutions.map(
        (execution, index) => ({
          ...execution,
          index,
          type:
            _updatingCampaign.campaignType !== 'One-off'
              ? ExecutionType.MANUAL
              : ExecutionType.AUTOMATIC,
          scheduledStartDate: dayjs(execution.scheduledStartDate).format(
            'MM/DD/YYYY HH:mm'
          ),
          campaignCommunications: ['Email', 'Text'].map(type => {
            const communication = execution.campaignCommunications.find(
              item => item.communicationType === type
            );
            if (!communication) {
              const campaignCommunication = {
                templateId: '',
                senderId: '',
                communicationType: type,
                title: '',
                enabled: false
              };
              if (
                _updatingCampaign.status === 'draft' &&
                _updatingCampaign.communicationTypes.find(item => item === type)
              ) {
                campaignCommunication.enabled = true;
              }

              return campaignCommunication;
            }
            return {
              ...communication,
              enabled: communication.templateId !== ''
            };
          })
        })
      ),

      scheduledStartDate: dayjs(new Date()).format('MM/DD/YYYY HH:mm'),
      numberOfExecutions: null,
      recurrencePattern: '',
      emailSender: emailSender,
      textSender: textSender
    });
    setShowUpdateCampaignModal(true);
  };

  const startCampaign = async campaign => {
    try {
      await axiosClient.put(`/campaigns/${campaign.id}/start`);
      toast.success('Campaign started successfully');
      fetchCampaigns();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (limit) {
      setPageCount(Math.ceil(total / limit));
    }
  }, [limit, total]);

  useEffect(() => {
    if (user) {
      fetchCampaigns();
    }
  }, [limit, page, user, sort]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setPage(1);
    }, 300);

    // Clear the timeout if search changes (before the delay ends)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (user) {
      fetchCampaigns();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await axiosClient.get('/customer-segments');
        setSegments(response.data.customerSegments);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Error to get segments');
        }
      }
    };

    const fetchGroups = async () => {
      try {
        const response = await axiosClient.get('/groups');
        setGroups(response.data.groups);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Error to get groups');
        }
      }
    };

    const fetchContacts = async () => {
      try {
        const response = await axiosClient.get('/contacts');
        setContacts(response.data.contacts);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Error to get contacts');
        }
      }
    };

    const fetchTemplates = async () => {
      try {
        const response = await axiosClient.get(`/templates`);
        setTemplates(response.data);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || 'Error fetching templates';
        toast.error(errorMessage);
      }
    };

    const fetchSenders = async () => {
      try {
        const [emailSenderResponse, textSenderResponse] = await Promise.all([
          axiosClient.get('/email-account/senders'),
          axiosClient.get('/phone-numbers')
        ]);
        setEmailSenders(emailSenderResponse.data);
        setTextSenders(textSenderResponse.data);
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch senders');
      }
    };
    if (user) {
      fetchSegments();
      fetchGroups();
      fetchContacts();
      fetchTemplates();
      fetchSenders();
    }
  }, [user]);

  useEffect(() => {
    if (!showUpdateCampaignModal) setIsViewingCampaign(false);
  }, [showUpdateCampaignModal]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 border-bottom">
              <h5 className="mb-3">
                <FaFlag className="me-2" />
                Campaigns
              </h5>
              <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                <input
                  className="input-value flex-grow-1 mb-3 mb-md-0"
                  label="Value"
                  name="value-0"
                  type="text"
                  placeholder="Search for name, description"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                {organization?.organizationRoles.find(
                  item => item.role === OrganizationRole.ADMIN
                ) && (
                  <div className="d-flex gap-2">
                    <Button
                      variant="outline-danger"
                      className={`${!selectedCampaignsCount ? 'disable' : ''}`}
                      disabled={!selectedCampaignsCount}
                      onClick={() => {
                        const ids = campaigns
                          .filter(campaign => campaign.selected)
                          .map(campaign => campaign.id);
                        setDeletingCampaignIds(ids);
                        setShowDeleteCampaignModal(true);
                      }}
                    >
                      <AiFillDelete /> Delete
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        if (
                          organization.textSendingEnabled ||
                          organization.emailSendingEnabled
                        ) {
                          setShowAddCampaignModal(true);
                        } else {
                          setShowCampaignErrorModal(true);
                        }
                      }}
                    >
                      <FaPlus /> Create
                    </Button>
                  </div>
                )}
              </div>
            </Card.Header>

            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || campaigns.length < 0
                      ? 'calc(100vh - 250px)'
                      : ''
                }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className=" cursor-pointer"
                      onClick={onHeaderCheckBoxClick}
                    >
                      {selectedCampaignsCount > 0 ? (
                        selectedCampaignsCount === campaigns.length ? (
                          <FaCheckSquare className="text-primary" />
                        ) : (
                          <FaMinusSquare className="text-primary" />
                        )
                      ) : (
                        <FaRegSquare />
                      )}
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('createdAt')}
                    >
                      <div className="d-flex justify-content-between">
                        Create date
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('createdAt', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('createdAt', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('name')}
                    >
                      <div className="d-flex justify-content-between">
                        Name
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('name', ASC)} />
                          <FaCaretDown style={getArrowStyles('name', DESC)} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('description')}
                    >
                      <div className="d-flex justify-content-between">
                        Description
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('description', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('description', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('campaignType')}
                    >
                      <div className="d-flex justify-content-between">
                        Campaign type
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('campaignType', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('campaignType', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('objective')}
                    >
                      <div className="d-flex justify-content-between">
                        Objective
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('objective', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('objective', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('status')}
                    >
                      <div className="d-flex justify-content-between">
                        Status
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('status', ASC)} />
                          <FaCaretDown style={getArrowStyles('status', DESC)} />
                        </span>
                      </div>
                    </th>

                    <th scope="col">
                      <div className="d-flex justify-content-between">
                        Communication types
                      </div>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : campaigns.length > 0 ? (
                    campaigns.map((campaign, index) => (
                      <tr
                        key={`campaign-${index}`}
                        onClick={() => onCampaignClick(index)}
                        className={`${campaign.selected ? 'bg-light' : ''}`}
                      >
                        <td>
                          {campaign.selected ? (
                            <FaCheckSquare className="text-primary fs-9" />
                          ) : (
                            <FaRegSquare className="fs-9" />
                          )}
                        </td>
                        <td>
                          {dayjs(campaign.createdAt).format('MM/DD/YYYY HH:mm')}
                        </td>
                        <td>{campaign.name}</td>
                        <td>{campaign.description}</td>
                        <td>{campaign.campaignType}</td>
                        <td>{campaign.objective}</td>
                        <td>{campaign.status}</td>
                        <td>{campaign.communicationTypes.join(', ')}</td>
                        <td className="px-0">
                          <div className="d-flex gap-2">
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id={`view-button-${index}`}
                                >
                                  View
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-warning"
                                onClick={e => {
                                  e.stopPropagation();
                                  handleUpdateCampaign({ ...campaign });
                                  setIsViewingCampaign(true);
                                }}
                              >
                                <MdRemoveRedEye />
                              </Button>
                            </OverlayTrigger>
                            {organization?.organizationRoles.find(
                              item => item.role === OrganizationRole.ADMIN
                            ) && (
                              <>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{
                                        position: 'fixed',
                                        zIndex: 1000
                                      }}
                                      id={`start-button-${index}`}
                                    >
                                      Start
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="outline-success"
                                    disabled={
                                      campaign.status === 'in progress' ||
                                      campaign.status === 'draft'
                                    }
                                    onClick={e => {
                                      e.stopPropagation();
                                      startCampaign({ ...campaign });
                                    }}
                                  >
                                    <IoPlay />
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{
                                        position: 'fixed',
                                        zIndex: 1000
                                      }}
                                      id={`edit-button-${index}`}
                                    >
                                      Edit
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="outline-info"
                                    onClick={e => {
                                      e.stopPropagation();
                                      handleUpdateCampaign({ ...campaign });
                                    }}
                                  >
                                    <FaEdit />
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{
                                        position: 'fixed',
                                        zIndex: 1000
                                      }}
                                      id={`delete-button-${index}`}
                                    >
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="outline-danger"
                                    onClick={e => {
                                      e.stopPropagation();
                                      setShowDeleteCampaignModal(true);
                                      setDeletingCampaignIds([campaign.id]);
                                    }}
                                  >
                                    <AiFillDelete />
                                  </Button>
                                </OverlayTrigger>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        No campaigns found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="position-relative">
                <CustomPagination
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
                {campaigns.length > 0 && (
                  <Dropdown
                    className="segment-dropdown position-absolute top-0 end-0"
                    style={{ minWidth: '70px' }}
                    onSelect={e => {
                      setLimit(e);
                      setPage(1);
                    }}
                  >
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {limit}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      {[10, 20, 50, 100].map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item}
                          className="text-capitalize"
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddCampaignModal
        showAddCampaignModal={showAddCampaignModal}
        setShowAddCampaignModal={setShowAddCampaignModal}
        fetchCampaigns={fetchCampaigns}
        emailSenders={emailSenders}
        textSenders={textSenders}
        templates={templates}
        segments={segments}
        groups={groups}
        contacts={contacts}
      />
      {updatingCampaign && (
        <UpdateCampaignModal
          showUpdateCampaignModal={showUpdateCampaignModal}
          setShowUpdateCampaignModal={setShowUpdateCampaignModal}
          fetchCampaigns={fetchCampaigns}
          updatingCampaign={updatingCampaign}
          emailSenders={emailSenders}
          textSenders={textSenders}
          templates={templates}
          segments={segments}
          groups={groups}
          contacts={contacts}
          isViewingCampaign={isViewingCampaign}
        />
      )}
      {deletingCampaignIds.length > 0 && (
        <DeleteCampaignModal
          showDeleteCampaignModal={showDeleteCampaignModal}
          setShowDeleteCampaignModal={setShowDeleteCampaignModal}
          deletingCampaignIds={deletingCampaignIds}
          setDeletingCampaignIds={setDeletingCampaignIds}
          fetchCampaigns={fetchCampaigns}
        />
      )}
      <Modal
        show={showCampaignErrorModal}
        onHide={handleCloseCampaignErrorModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Campaign Creation Not Allowed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Campaign creation is not allowed because no communication methods
          (email or text) are enabled for your account
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCampaignErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CampaignsPage;
