import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip
} from 'react-bootstrap';

import GroupModal from 'components/groups/GroupModal';
import DeleteModal from 'components/common/modal/DeleteModal';
import CustomPagination from 'components/pagination';
import { AiFillDelete } from 'react-icons/ai';
import {
  FaCheckSquare,
  FaEdit,
  FaMinusSquare,
  FaRegSquare
} from 'react-icons/fa';
import { FaPlus, FaUsers } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/reducer/auth';
import { useAppSelector } from 'redux/store';
import { selectOrganization } from 'redux/reducer/auth';
import { OrganizationRole } from 'data/common';
import axiosClient from 'services/axios';
import './styles.scss';
import SubtleBadge from 'components/common/SubtleBadge';
import { Link } from 'react-router-dom';
import { MdRemoveRedEye } from 'react-icons/md';

const GroupsPage = () => {
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const [isFetching, setIsFetching] = useState(true);

  const [groups, setGroups] = useState([]);

  const [showGroupModal, setShowGroupModal] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const [mode, setMode] = useState('ADD');

  const [isLoading, setIsLoading] = useState(false);
  const [groupDetail, setGroupDetail] = useState();
  const [deletingGroupIds, setDeletingGroupIds] = useState([]);
  const [showDeleteGroupsModal, setShowDeleteGroupsModal] = useState(false);
  const [contacts, setContacts] = useState();

  const fetchGroups = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(
        `/groups?page=${page}&limit=${limit}&search=${search}`
      );
      const _groups = response.data.groups.map(group => ({
        ...group,
        selected: false
      }));
      setGroups(_groups);
      setTotal(response.data.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get groups');
      }
    }
    setIsFetching(false);
  };

  const selectedGroupsCount = groups.filter(item => item.selected).length;

  const onGroupsClick = index => {
    const updatedGroups = [...groups];
    updatedGroups[index].selected = !updatedGroups[index].selected;
    setGroups(updatedGroups);
  };

  const onHeaderCheckBoxClick = () => {
    if (selectedGroupsCount === groups.length) {
      setGroups(
        groups.map(group => ({
          ...group,
          selected: false
        }))
      );
    } else {
      setGroups(
        groups.map(group => ({
          ...group,
          selected: true
        }))
      );
    }
  };

  useEffect(() => {
    if (limit) {
      setPageCount(Math.ceil(total / limit));
    }
  }, [limit, total]);

  useEffect(() => {
    if (user) {
      fetchGroups();
    }
  }, [limit, page, user]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setPage(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (user) {
      fetchGroups();
    }
  }, [debouncedSearch]);

  const handleDeleteGroups = async ids => {
    setIsLoading(true);
    try {
      await axiosClient.delete(`/groups/${ids.join(',')}`);
      fetchGroups();
      toast.success('Groups deleted successfully');
      setIsLoading(false);
      setShowDeleteGroupsModal(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const fetchContacts = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(`/contacts`);
      setContacts(response.data.contacts);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get contacts');
      }
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 border-bottom">
              <h5 className="mb-3">
                <FaUsers className="me-2" />
                Groups
              </h5>
              <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                <input
                  className="input-value flex-grow-1 mb-3 mb-md-0"
                  label="Value"
                  name="value-0"
                  type="text"
                  placeholder="Search for name"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                {organization?.organizationRoles.find(
                  item => item.role === OrganizationRole.ADMIN
                ) && (
                  <div className="d-flex gap-2">
                    <Button
                      variant="outline-danger"
                      className={`${!selectedGroupsCount ? 'disable' : ''}`}
                      disabled={!selectedGroupsCount}
                      onClick={() => {
                        const ids = groups
                          .filter(group => group.selected)
                          .map(group => group.id);
                        setDeletingGroupIds(ids);
                        setShowDeleteGroupsModal(true);
                      }}
                    >
                      <AiFillDelete /> Delete
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setShowGroupModal(true);
                        setMode('ADD');
                      }}
                    >
                      <FaPlus /> Create
                    </Button>
                  </div>
                )}
              </div>
            </Card.Header>

            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || groups.length < 0 ? 'calc(100vh - 250px)' : ''
                }}
              >
                <thead>
                  <tr>
                    <th
                      width="10"
                      scope="col"
                      className=" cursor-pointer"
                      onClick={onHeaderCheckBoxClick}
                    >
                      {selectedGroupsCount > 0 ? (
                        selectedGroupsCount === groups.length ? (
                          <FaCheckSquare className="text-primary" />
                        ) : (
                          <FaMinusSquare className="text-primary" />
                        )
                      ) : (
                        <FaRegSquare />
                      )}
                    </th>
                    <th width="20%" scope="col" className="cursor-pointer">
                      <div className="d-flex justify-content-between">Name</div>
                    </th>
                    <th width="70%" scope="col" className="cursor-pointer">
                      <div className="d-flex justify-content-between">
                        Contacts
                      </div>
                    </th>
                    <th width="50" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : groups.length > 0 ? (
                    groups.map((group, index) => (
                      <tr
                        key={`custom-field-${index}`}
                        onClick={() => onGroupsClick(index)}
                        className={`${group.selected ? 'bg-light' : ''}`}
                      >
                        <td>
                          {group.selected ? (
                            <FaCheckSquare className="text-primary fs-9" />
                          ) : (
                            <FaRegSquare className="fs-9" />
                          )}
                        </td>
                        <td>
                          <Link to={`/groups/${group.id}`}>{group.name}</Link>
                        </td>
                        <td className="text-capitalize">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${group.id}`}>
                                {group.contactIds.map(contactId => {
                                  const contact = contacts?.find(
                                    item => item.id === contactId
                                  );
                                  return contact ? (
                                    <div key={contactId}>
                                      {contact.firstName} {contact.lastName}
                                    </div>
                                  ) : null;
                                })}
                              </Tooltip>
                            }
                          >
                            <span>
                              {group?.contactIds
                                ?.slice(0, 5)
                                .map((contactId, index) => {
                                  const contact = contacts?.find(
                                    item => item.id === contactId
                                  );
                                  return contact ? (
                                    <SubtleBadge
                                      key={index}
                                      variant="primary"
                                      className="me-1"
                                    >
                                      {contact.firstName} {contact.lastName}
                                    </SubtleBadge>
                                  ) : null;
                                })}
                              {group?.contactIds?.length > 5 && (
                                <SubtleBadge
                                  variant="secondary"
                                  className="me-1"
                                >
                                  +{group.contactIds.length - 5} more
                                </SubtleBadge>
                              )}
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td colSpan="2">
                          <div className="d-flex gap-2">
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id={`view-button-${index}`}
                                >
                                  View
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-warning"
                                onClick={e => {
                                  e.stopPropagation();
                                  setShowGroupModal(true);
                                  setMode('VIEW');
                                  setGroupDetail(group);
                                }}
                              >
                                <MdRemoveRedEye />
                              </Button>
                            </OverlayTrigger>
                            {organization?.organizationRoles.find(
                              item => item.role === OrganizationRole.ADMIN
                            ) && (
                              <>
                                {' '}
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{
                                        position: 'fixed',
                                        zIndex: 1000
                                      }}
                                      id={`edit-button-${index}`}
                                    >
                                      Edit
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="outline-info"
                                    onClick={e => {
                                      e.stopPropagation();
                                      setShowGroupModal(true);
                                      setMode('EDIT');
                                      setGroupDetail(group);
                                    }}
                                  >
                                    <FaEdit />
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{
                                        position: 'fixed',
                                        zIndex: 1000
                                      }}
                                      id={`delete-button-${index}`}
                                    >
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="outline-danger"
                                    onClick={e => {
                                      e.stopPropagation();
                                      setDeletingGroupIds([group.id]);
                                      setShowDeleteGroupsModal(true);
                                    }}
                                  >
                                    <AiFillDelete />
                                  </Button>
                                </OverlayTrigger>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        No groups found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="position-relative">
                <CustomPagination
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
                {groups.length > 0 && (
                  <Dropdown
                    className="segment-dropdown position-absolute top-0 end-0"
                    style={{ minWidth: '70px' }}
                    onSelect={e => {
                      setLimit(e);
                      setPage(1);
                    }}
                  >
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {limit}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      {[10, 20, 50, 100].map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item}
                          className="text-capitalize"
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <GroupModal
        showGroupModal={showGroupModal}
        setShowGroupModal={setShowGroupModal}
        fetchGroups={fetchGroups}
        groupDetail={groupDetail}
        mode={mode}
        contacts={contacts}
      />
      <DeleteModal
        name="group"
        showDeleteModal={showDeleteGroupsModal}
        setShowDeleteModal={setShowDeleteGroupsModal}
        deletingIds={deletingGroupIds}
        setDeletingIds={setDeletingGroupIds}
        isLoading={isLoading}
        handleDelete={handleDeleteGroups}
      />
    </>
  );
};

export default GroupsPage;
