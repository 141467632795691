import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import PropTypes from 'prop-types';

const DeleteModal = ({
  name,
  showDeleteModal,
  setShowDeleteModal,
  deletingIds,
  setDeletingIds,
  isLoading,
  handleDelete
}) => {
  return (
    <Modal
      show={showDeleteModal}
      onHide={() => {
        setShowDeleteModal(false);
        setDeletingIds([]);
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete {name}
          {deletingIds.length > 1 ? 's' : ''}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <div className="d-flex gap-2">
            <span>
              Are you sure you want to delete{' '}
              <b>
                {deletingIds.length} {name}
                {deletingIds.length > 1 ? 's' : ''}
              </b>
              ?
            </span>
          </div>
        )}
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button
          variant="secondary"
          onClick={() => {
            setShowDeleteModal(false);
            setDeletingIds([]);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={isLoading}
          onClick={() => {
            handleDelete(deletingIds);
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  name: PropTypes.string.isRequired,
  showDeleteModal: PropTypes.bool.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
  deletingIds: PropTypes.array.isRequired,
  setDeletingIds: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default DeleteModal;
