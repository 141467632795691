import React from 'react';
import PropTypes from 'prop-types';

import Divider from 'components/common/Divider';
import CampaignForm from './CampaignForm';
import ScheduleForm from './ScheduleForm';

const Review = ({
  errors,
  control,
  register,
  campaign,
  setCampaign,
  setValue,
  clearErrors,
  emailSenders,
  textSenders,
  templates,
  segments,
  groups,
  contacts,
  isViewingCampaign
}) => {
  return (
    <div
      className="overflow-y-auto px-4 px-md-6 py-4"
      style={{ maxHeight: 700 }}
    >
      <>
        <h5 className="text-center">Campaign details</h5>
        <CampaignForm
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          campaign={campaign}
          setCampaign={setCampaign}
          isViewingCampaign={isViewingCampaign}
        />
      </>
      <>
        <Divider />
        <h5 className="text-center">Executions</h5>
        <ScheduleForm
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          campaign={campaign}
          setCampaign={setCampaign}
          emailSenders={emailSenders}
          textSenders={textSenders}
          templates={templates}
          segments={segments}
          groups={groups}
          contacts={contacts}
          isViewingCampaign={isViewingCampaign}
        />
      </>
    </div>
  );
};

Review.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  campaign: PropTypes.object,
  setCampaign: PropTypes.func,
  emailSenders: PropTypes.array,
  textSenders: PropTypes.array,
  templates: PropTypes.array,
  segments: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  isViewingCampaign: PropTypes.bool
};

export default Review;
