import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint } from 'config';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes from 'routes/siteMaps';
import { capitalize } from 'helpers/utils';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import { useAppContext } from 'providers/AppProvider';
import { selectUser } from 'redux/reducer/auth';
import { useAppSelector } from 'redux/store';

const NavbarVertical = () => {
  const {
    config: { navbarStyle, isNavbarVerticalCollapsed, showBurgerMenu }
  } = useAppContext();

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const user = useAppSelector(selectUser);
  const [leftMenu, setLeftMenu] = React.useState(routes);
  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  useEffect(() => {
    if (user) {
      const updatedLeftMenu = {
        ...routes[0],
        children: routes[0].children.map(route => {
          if (route.name === 'Templates') {
            return {
              ...route,
              children: [
                {
                  name: user.isAdmin
                    ? 'User Email Templates'
                    : 'Custom Templates',
                  to: user.isAdmin
                    ? '/templates/user-email-template'
                    : '/templates/custom-email-template',
                  active: true
                },
                ...route.children
              ]
            };
          }
          return route;
        })
      };

      if (user.isAdmin) {
        updatedLeftMenu.children.splice(1, 0, {
          name: 'Administration',
          icon: 'user',
          active: true,
          children: [
            {
              name: 'Users',
              to: '/users',
              active: true
            },
            {
              name: 'Request',
              // icon: 'lock',
              active: true,
              children: [
                {
                  name: 'User access request',
                  to: '/user-access-request',
                  active: true
                }
              ]
            }
          ]
        });
      }

      setLeftMenu([updatedLeftMenu]);
    }
  }, [user]);

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" textClass="text-primary" width={40} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : 'none'
        }}
      >
        <div className="navbar-vertical-content scrollbar">
          <Nav className="flex-column" as="ul">
            {user &&
              leftMenu.map(route => (
                <Fragment key={route.label}>
                  {!route.labelDisable && (
                    <NavbarLabel label={capitalize(route.label)} />
                  )}
                  <NavbarVerticalMenu routes={route.children} />
                </Fragment>
              ))}
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
