import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const DeleteCustomerSegmentModal = ({
  showDeleteCustomerSegmentModal,
  setShowDeleteCustomerSegmentModal,
  deletingCustomerSegmentIds,
  setDeletingCustomerSegmentIds,
  fetchCustomerSegments
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteCustomerSegments = async ids => {
    setIsLoading(true);
    try {
      await axiosClient.delete(`/customer-segments/${ids.join(',')}`);
      fetchCustomerSegments();
      toast.success('Customer segments deleted successfully');
      setIsLoading(false);
      setShowDeleteCustomerSegmentModal(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Modal
      show={showDeleteCustomerSegmentModal}
      onHide={() => {
        setShowDeleteCustomerSegmentModal(false);
        setDeletingCustomerSegmentIds([]);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Customer Segment
          {deletingCustomerSegmentIds.length > 1 ? 's' : ''}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <div className="d-flex gap-2">
            <span>
              Are you sure you want to delete{' '}
              <b>
                {deletingCustomerSegmentIds.length} customer segment
                {deletingCustomerSegmentIds.length > 1 ? 's' : ''}
              </b>
              ?
            </span>
          </div>
        )}
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button
          variant="secondary"
          onClick={() => {
            setShowDeleteCustomerSegmentModal(false);
            setDeletingCustomerSegmentIds([]);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={isLoading}
          onClick={() => {
            handleDeleteCustomerSegments(deletingCustomerSegmentIds);
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

DeleteCustomerSegmentModal.propTypes = {
  showDeleteCustomerSegmentModal: PropTypes.bool.isRequired,
  setShowDeleteCustomerSegmentModal: PropTypes.func.isRequired,
  deletingCustomerSegmentIds: PropTypes.array.isRequired,
  setDeletingCustomerSegmentIds: PropTypes.func.isRequired,
  fetchCustomerSegments: PropTypes.func.isRequired
};

export default DeleteCustomerSegmentModal;
