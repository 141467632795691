import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'services/axios';

const initialState = {
  notifications: [],
  isLoading: false,
  error: ''
};

export const setToken = createAsyncThunk('token', data => {
  return data;
});

export const getNotification = createAsyncThunk(
  'notification',
  async (valueForm, thunkAPI) => {
    try {
      const res = await axiosClient.get('/notifications', valueForm);
      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNotification.pending, state => {
        state.isLoading = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.isLoading = false;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.notifications = [];
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const selectNotifications = state => state.notification.notifications;
export default notificationSlice.reducer;
