import React from 'react';
import PropTypes from 'prop-types';
import { Card, Placeholder } from 'react-bootstrap';
import classNames from 'classnames';
import BounceEmailEventChart from './BounceEmailEventChart';

const BounceEmailEvent = ({
  chartData,
  className,
  period,
  isEmailUsageFetching
}) => {
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Bounce</h5>
        <p className="mb-0 fs-11">
          The percentage of emails that resulted in a hard bounce.
        </p>
      </Card.Header>

      <Card.Body>
        {isEmailUsageFetching ? (
          <Placeholder as="div" animation="glow">
            <Placeholder xs={12} style={{ height: '21.25rem' }} />
          </Placeholder>
        ) : (
          <BounceEmailEventChart
            data={chartData['Bounce']}
            event="Bounce"
            period={period}
          />
        )}
      </Card.Body>
    </Card>
  );
};

BounceEmailEvent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.array),
  className: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  isEmailUsageFetching: PropTypes.bool.isRequired
};

export default BounceEmailEvent;
