// import AddEditTextTemplateModal from 'components/text-templates/AddEditTextTemplateModal';
import AddTextTemplateModal from 'components/text-templates/AddTextTemplateModal';
import DeleteTextTemplateModal from 'components/text-templates/DeleteTextTemplateModal';
import EditTextTemplateModal from 'components/text-templates/EditTextTemplateModal';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip
} from 'react-bootstrap';
import { AiFillDelete } from 'react-icons/ai';
import {
  FaCheckSquare,
  FaEdit,
  FaMinusSquare,
  FaRegSquare,
  FaComments
} from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import { useAppSelector } from 'redux/store';
import { selectOrganization } from 'redux/reducer/auth';
import { OrganizationRole } from 'data/common';

// const ASC = 'ASC';
// const DESC = 'DESC';

const TextTemplatesPage = () => {
  const organization = useAppSelector(selectOrganization);
  const [texts, setTexts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [openAddTextModal, setOpenAddTextModal] = useState(false);

  const [openEditTextModal, setOpenEditTextModal] = useState(false);
  const [editingTextTemplate, setEditingTextTemplate] = useState([]);

  const [openDeleteTextModal, setOpenDeleteTextModal] = useState(false);
  const [deletingTextIds, setDeletingTextIds] = useState();

  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(10);
  // const [total, setTotal] = useState(0);
  // const [pageCount, setPageCount] = useState(0);

  // const [sort, setSort] = useState({
  //   fieldName: 'field_name',
  //   order: ASC
  // });

  const selectedTextsCount = useMemo(
    () => texts.filter(item => item.selected).length,
    [texts]
  );

  const fetchData = async () => {
    try {
      setIsFetching(true);
      const response = await axiosClient.get(`/templates/custom?type=text`);
      const _texts = response.data.map(item => ({
        ...item,
        selected: false
      }));
      setTexts(_texts);
      // setTotal(response.data.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get custom fields');
      }
    }
    setIsFetching(false);
  };

  const onTextsClick = index => {
    const updatedTexts = [...texts];
    updatedTexts[index].selected = !updatedTexts[index].selected;
    setTexts(updatedTexts);
  };

  const onHeaderCheckBoxClick = () => {
    if (selectedTextsCount === texts.length) {
      setTexts(
        texts.map(customField => ({
          ...customField,
          selected: false
        }))
      );
    } else {
      setTexts(
        texts.map(customField => ({
          ...customField,
          selected: true
        }))
      );
    }
  };

  //add
  const handleSubmitAddModal = async newTemplate => {
    setIsLoading(true);
    try {
      await axiosClient.post(`/templates/text`, newTemplate);
      toast.success('Template created successfully');
      fetchData();
      setOpenAddTextModal(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to create template');
      }
    }
    setIsLoading(false);
  };

  const handleCloseAddModal = () => {
    setOpenAddTextModal(false);
  };

  //edit
  const handleSubmitEditModal = async formData => {
    setIsLoading(true);

    try {
      await axiosClient.put(
        `/templates/text/${editingTextTemplate.id}`,
        formData
      );
      toast.success('Template updated successfully');
      fetchData();
      setOpenEditTextModal(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Failed to update template';
      toast.error(errorMessage);
    }
    setIsLoading(false);
  };

  const handleCloseEditModal = () => {
    setOpenEditTextModal(false);
  };

  //delete

  const handleCloseDeleteModal = () => {
    setOpenDeleteTextModal(false);
    setDeletingTextIds([]);
  };

  const handleSubmitDeleteModal = async () => {
    setIsLoading(true);

    try {
      await axiosClient.delete(`/templates/${deletingTextIds.join(',')}`);
      fetchData();
      toast.success('Templates deleted successfully');
      setOpenDeleteTextModal(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to delete template');
      }
    }
    setIsLoading(false);
  };

  // const getArrowStyles = (fieldName, order) => {
  //   if (sort.fieldName === fieldName && sort.order === order) {
  //     return {
  //       color: '#748194'
  //     };
  //   } else {
  //     return {
  //       color: '#d8e2ef'
  //     };
  //   }
  // };

  // const onSortChange = fieldName => {
  //   if (sort.fieldName === fieldName) {
  //     setSort({
  //       fieldName,
  //       order: sort.order === ASC ? DESC : ASC
  //     });
  //   } else {
  //     setSort({
  //       fieldName,
  //       order: ASC
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (limit) {
  //     setPageCount(Math.ceil(total / limit));
  //   }
  // }, [limit, total]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 border-bottom">
              {/* Container for Header and Buttons */}
              <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                {/* Header with Icon */}
                <h5 className="mb-3 mb-md-0">
                  <FaComments className="me-2" />
                  Text Templates
                </h5>

                {/* Buttons Container */}
                {organization?.organizationRoles.find(
                  item => item.role === OrganizationRole.ADMIN
                ) && (
                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      variant="outline-danger"
                      className={`${
                        !selectedTextsCount ? 'disable' : ''
                      } mb-3 mb-md-0`}
                      disabled={!selectedTextsCount}
                      onClick={() => {
                        const ids = texts
                          .filter(item => item.selected)
                          .map(item => item.id);
                        setDeletingTextIds(ids);
                        setOpenDeleteTextModal(true);
                      }}
                    >
                      <AiFillDelete className="me-1" />
                      Delete
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => setOpenAddTextModal(true)}
                      disabled={isFetching}
                      className="mb-3 mb-md-0"
                    >
                      <FaPlus className="me-1" />
                      Create
                    </Button>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || texts.length < 0 ? 'calc(100vh - 250px)' : ''
                }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className=" cursor-pointer"
                      onClick={onHeaderCheckBoxClick}
                    >
                      {selectedTextsCount > 0 ? (
                        selectedTextsCount === texts.length ? (
                          <FaCheckSquare className="text-primary" />
                        ) : (
                          <FaMinusSquare className="text-primary" />
                        )
                      ) : (
                        <FaRegSquare />
                      )}
                    </th>
                    <th
                      scope="col"
                      // className="cursor-pointer"
                      // onClick={() => onSortChange('field_name')}
                    >
                      <div className="d-flex justify-content-between min-w-100">
                        Title
                        {/* <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('field_name', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('field_name', DESC)}
                          />
                        </span> */}
                      </div>
                    </th>

                    <th
                      scope="col"
                      // className="cursor-pointer"
                      // onClick={() => onSortChange('field_type')}
                    >
                      <div className="d-flex justify-content-between min-w-100">
                        Content
                        {/* <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('field_type', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('field_type', DESC)}
                          />
                        </span> */}
                      </div>
                    </th>
                    <th width="50" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : texts.length > 0 ? (
                    texts.map((item, index) => (
                      <tr
                        key={`custom-field-${index}`}
                        onClick={() => onTextsClick(index)}
                        className={`${item.selected ? 'bg-light' : ''}`}
                      >
                        <td className=" cursor-pointer">
                          {item.selected ? (
                            <FaCheckSquare className="text-primary fs-9" />
                          ) : (
                            <FaRegSquare className="fs-9" />
                          )}
                        </td>
                        <td>{item.name}</td>
                        <td>{item.content}</td>
                        {organization?.organizationRoles.find(
                          item => item.role === OrganizationRole.ADMIN
                        ) && (
                          <td>
                            <div className="d-flex gap-2">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip
                                    style={{ position: 'fixed', zIndex: 1000 }}
                                    id={`edit-button-${index}`}
                                  >
                                    Edit
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-info"
                                  disabled={isFetching}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setEditingTextTemplate(item);
                                    setOpenEditTextModal(true);
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip
                                    style={{ position: 'fixed', zIndex: 1000 }}
                                    id={`delete-button-${index}`}
                                  >
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-danger"
                                  disabled={isFetching}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setOpenDeleteTextModal(true);
                                    setDeletingTextIds([item.id]);
                                  }}
                                >
                                  <AiFillDelete />
                                </Button>
                              </OverlayTrigger>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        No text templates found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* <div className="position-relative">
                <CustomPagination
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
                {texts.length > 0 && (
                  <Dropdown
                    className="segment-dropdown position-absolute top-0 end-0"
                    style={{ minWidth: '70px' }}
                    onSelect={e => {
                      setLimit(e);
                      setPage(1);
                    }}
                  >
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {limit}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      {[10, 20, 50, 100].map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item}
                          className="text-capitalize"
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {openAddTextModal && (
        <AddTextTemplateModal
          openModal={openAddTextModal}
          handleCloseModal={handleCloseAddModal}
          handleSubmitModal={handleSubmitAddModal}
          isLoading={isLoading}
        />
      )}
      {editingTextTemplate && openEditTextModal && (
        <EditTextTemplateModal
          openModal={openEditTextModal}
          data={editingTextTemplate}
          handleCloseModal={handleCloseEditModal}
          handleSubmitModal={handleSubmitEditModal}
          isLoading={isLoading}
        />
      )}
      {deletingTextIds && openDeleteTextModal && (
        <DeleteTextTemplateModal
          openModal={openDeleteTextModal}
          handleCloseModal={handleCloseDeleteModal}
          data={deletingTextIds}
          handleSubmitModal={handleSubmitDeleteModal}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default TextTemplatesPage;
