import React from 'react';
import PropTypes from 'prop-types';
import { Card, Placeholder } from 'react-bootstrap';
import classNames from 'classnames';
import ComplaintsEmailEventChart from './ComplaintsEmailEventChart';

const ComplaintsEmailEvent = ({
  chartData,
  className,
  period,
  isEmailUsageFetching
}) => {
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Complaints</h5>
        <p className="mb-0 fs-11">
          The percentage of emails reported as spam by the recipients.
        </p>
      </Card.Header>

      <Card.Body>
        {isEmailUsageFetching ? (
          <Placeholder as="div" animation="glow">
            <Placeholder xs={12} style={{ height: '21.25rem' }} />
          </Placeholder>
        ) : (
          <ComplaintsEmailEventChart
            data={chartData['Complaints']}
            event="Complaints"
            period={period}
          />
        )}
      </Card.Body>
    </Card>
  );
};

ComplaintsEmailEvent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.array),
  className: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  isEmailUsageFetching: PropTypes.bool.isRequired
};

export default ComplaintsEmailEvent;
