import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';

const CustomPagination = ({ page, pageCount, setPage }) => {
  const renderPaginationItems = () => {
    const paginationItems = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, page - halfMaxPagesToShow);
    let endPage = Math.min(pageCount, page + halfMaxPagesToShow);

    if (page <= halfMaxPagesToShow) {
      endPage = Math.min(pageCount, maxPagesToShow);
    } else if (page > pageCount - halfMaxPagesToShow) {
      startPage = Math.max(1, pageCount - maxPagesToShow + 1);
    }

    if (startPage > 1) {
      paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < pageCount) {
      paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    return paginationItems;
  };

  return (
    pageCount > 0 && (
      <Pagination className="justify-content-center my-4">
        <Pagination.First onClick={() => setPage(1)} />
        {renderPaginationItems()}
        <Pagination.Last onClick={() => setPage(pageCount)} />
      </Pagination>
    )
  );
};

CustomPagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
};

export default CustomPagination;
