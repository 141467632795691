import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import Divider from 'components/common/Divider';
import WizardInput from 'components/wizard/WizardInput';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { RequestStatus } from 'data/common';

const RequestModal = ({
  showRequestModal,
  setShowRequestModal,
  fetchRequests,
  requestDetail,
  mode
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      fullName: '',
      email: '',
      status: ''
    }
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    reset();
    setMessage('');
  }, [showRequestModal]);

  const handleUpdateRequest = async data => {
    setLoading(true);
    try {
      await axiosClient.put(`/requests/${requestDetail.id}`, data);
      fetchRequests();
      toast.success('User Access Request updated successfully');
      setShowRequestModal(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || 'User Access Request update failed'
      );
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = data => {
    if (mode === 'EDIT') {
      handleUpdateRequest(data);
    }
  };

  const handleSendMessage = async () => {
    setLoading(true);
    try {
      await axiosClient.post(`/requests/${requestDetail.id}/send-message`, {
        message: message.replace(/\n/g, '<br>')
      });
      fetchRequests();
      toast.success('Message sent successfully');
      setShowRequestModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Message sending failed');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (requestDetail && mode !== 'ADD') {
      const transformData = {
        ...requestDetail
      };

      Object.keys(transformData).forEach(item => {
        setValue(item, transformData[item]);
      });
    }
  }, [requestDetail]);

  useEffect(() => {
    if (requestDetail && mode !== 'ADD') {
      const transformData = {
        ...requestDetail
      };

      Object.keys(transformData).forEach(item => {
        setValue(item, transformData[item]);
      });
    }
  }, [requestDetail]);

  return (
    <Modal
      show={showRequestModal}
      onHide={() => {
        setShowRequestModal(false);
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {mode === 'EDIT'
            ? 'Update User Access Request'
            : 'Add User Access Request'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Col>
              <WizardInput
                label="Full name"
                name="fullName"
                errors={errors}
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                  ...register('fullName', {
                    required: 'Full name is required'
                  }),
                  disabled: true
                }}
              />
              <WizardInput
                type="email"
                errors={errors}
                label="Email*"
                name="email"
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                  ...register('email', {
                    required: 'Email field is required',
                    pattern: {
                      value:
                        /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                      message: 'Email must be valid'
                    }
                  }),
                  disabled: true
                }}
              />
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Form.Group controlId="status" className="mb-0">
                    <Form.Label className="mb-0 text-700">Status</Form.Label>
                    <div className="d-flex gap-4">
                      <Form.Check
                        type="radio"
                        label="Approved"
                        id="approved"
                        {...field}
                        value={RequestStatus.APPROVED}
                        checked={field.value === RequestStatus.APPROVED}
                        className="mb-1"
                      />
                      <Form.Check
                        type="radio"
                        label="Disapproved"
                        id="disapproved"
                        {...field}
                        value={RequestStatus.DISAPPROVED}
                        checked={field.value === RequestStatus.DISAPPROVED}
                        className="mb-0"
                      />
                    </div>
                  </Form.Group>
                )}
              />

              <WizardInput
                type="textarea"
                label="Message"
                name="message"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  value: message,
                  onChange: e => setMessage(e.target.value)
                }}
              />
            </Col>
          </Form>
        )}
      </Modal.Body>
      <Divider />
      <div className="col-12 d-flex gap-3 justify-content-center align-items-center px-4 mb-3">
        <Button
          disabled={loading || !message}
          type="button"
          onClick={handleSendMessage}
          variant="outline-primary"
        >
          Send Message
        </Button>
        <Button
          disabled={loading}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

RequestModal.propTypes = {
  showRequestModal: PropTypes.bool,
  setShowRequestModal: PropTypes.func,
  fetchRequests: PropTypes.func,
  requestDetail: PropTypes.object,
  mode: PropTypes.oneOf(['VIEW', 'ADD', 'EDIT'])
};

export default RequestModal;
