import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { selectNotifications } from 'redux/reducer/notification';
import { useAppSelector } from 'redux/store';
import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';
import createMarkup from 'helpers/createMarkup';

const Notifications = ({ campaign }) => {
  const notification = useAppSelector(selectNotifications);
  const [campaignNotifications, setCampaignNotifications] = useState([]);

  useEffect(() => {
    if (notification) {
      const campaignNotifications = notification.filter(
        item => item.campaignId === campaign.id
      );
      setCampaignNotifications(campaignNotifications);
    }
  }, [notification, campaign.id]);

  return (
    <div style={{ maxHeight: '500px', overflowY: 'auto' }} className="px-2">
      <Table responsive>
        <thead>
          <tr>
            <th scope="col">From</th>
            <th scope="col">Content</th>
            <th scope="col">Status</th>
            <th scope="col">Created at</th>
          </tr>
        </thead>
        <tbody>
          {campaignNotifications.length > 0 ? (
            campaignNotifications.map((item, index) => (
              <tr key={index}>
                <td>{item.from}</td>
                <td>
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={createMarkup(item.content)}
                  />
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={createMarkup(item.detail)}
                  />
                </td>
                <td>{item.markedAsRead ? 'Read' : 'Unread'}</td>
                <td>{dayjs(item.createdAt).format('MM/DD/YYYY HH:mm')}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">
                No notification found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

Notifications.propTypes = {
  loading: PropTypes.boolean,
  campaign: PropTypes.object,
  segments: PropTypes.array
};

export default Notifications;
