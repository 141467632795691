import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { rgbaColor } from 'helpers/utils';
import { periodType } from 'data/common';
import PropTypes from 'prop-types';
import { useAppContext } from 'providers/AppProvider';
import React, { useRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (getThemeColor, data, event, period) => ({
  color: getThemeColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    textStyle: { color: getThemeColor('gray-1100') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: params => {
      const point = params[0];
      const formattedDate =
        period === periodType.DAY
          ? point.axisValue
          : dayjs(point.axisValue).format(
              period === periodType.YEAR ? 'YYYY MMM' : 'MMM DD'
            );

      return `<div>
            <p class='mb-0 fs-11 text-600'>${formattedDate} </p>
            <div class="d-flex align-items-center">
              <p class='mb-0 text-600 fs-10'>
                ${event}: <span class='text-800 fw-semibold fs-10'>${point.data}</span>
              </p>
            </div>
          </div>`;
    }
  },
  xAxis: {
    type: 'category',
    data: data?.timePeriods,
    axisLabel: {
      color: getThemeColor('gray-600'),
      formatter: value =>
        period === periodType.DAY
          ? value
          : dayjs(value).format(
              period === periodType.YEAR ? 'YYYY MMM' : 'MMM DD'
            ),
      align: 'left',
      fontSize: 11,
      padding: [0, 0, 0, 5],
      showMaxLabel: false
    },
    axisLine: {
      lineStyle: {
        color: getThemeColor('gray-200')
      }
    },
    axisTick: {
      show: true,
      length: 20,
      lineStyle: {
        color: getThemeColor('gray-200')
      }
    },
    boundaryGap: false
  },
  yAxis: {
    position: 'right',
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getThemeColor('gray-200')
      }
    },
    axisLine: { show: false },
    axisLabel: { color: getThemeColor('gray-600') }
  },
  series: [
    {
      type: 'line',
      data: data?.counts,
      showSymbol: false,
      symbol: 'circle',
      animation: false,
      itemStyle: {
        borderColor: getThemeColor('primary'),
        borderWidth: 2
      },
      lineStyle: {
        color: getThemeColor('primary')
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getThemeColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getThemeColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: {
    containLabel: true,
    right: '5px',
    left: 0,
    bottom: 0,
    top: '10px'
  }
});

const ClickEmailEventChart = ({ data, event, period }) => {
  const chartRef = useRef(null);
  const { getThemeColor } = useAppContext();

  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getOptions(getThemeColor, data, event, period)}
      style={{ height: '21.25rem' }}
    />
  );
};

ClickEmailEventChart.propTypes = {
  data: PropTypes.object.isRequired,
  event: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired
};

export default ClickEmailEventChart;
