import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import DailyEmailUsage from 'components/dashboard/DailyEmailUsage';
import SentEmailVolume from 'components/dashboard/SentEmailVolume';
import SendingStatistics from 'components/dashboard/SendingStatistics';
import SendEmailEvent from 'components/dashboard/send-email-event/SendEmailEvent';
import OpenEmailEvent from 'components/dashboard/open-email-event/OpenEmailEvent';
import ClickEmailEvent from 'components/dashboard/click-email-event/ClickEmailEvent';
import DeliveredEmailEvent from 'components/dashboard/delivered-email-event/DeliveredEmailEvent';
import OptOutEmailEvent from 'components/dashboard/otp-out-email-event/OtpOutEmailEvent';
import EmailEvent from 'components/dashboard/email-event/EmailEvent';
import ComplaintsEmailEvent from 'components/dashboard/complaints-email-event/ComplaintsEmailEvent';
import BounceEmailEvent from 'components/dashboard/bounce-email-event/BounceEmailEvent';
import { useParams } from 'react-router-dom';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { periodType } from 'data/common';

const SenderDetailPage = () => {
  const { id } = useParams();
  const [isEmailEventFetching, setIsEmailEventFetching] = useState(false);
  const [isEmailUsageFetching, setIsEmailUsageFetching] = useState(false);
  const [emailEvent, setEmailEvent] = useState({});
  const [emailUsage, setEmailUsage] = useState({});

  const [emailUsagePeriod, setEmailUsagePeriod] = useState(periodType.DAY);
  const [emailEventPeriod, setEmailEventPeriod] = useState(periodType.WEEK);

  const fetchData = async (url, optOutUrl, setter, setterFetching) => {
    setterFetching(true);
    try {
      const response = await axiosClient.get(url);
      const rawData = response.data.data;
      const optOutResponse = await axiosClient.get(optOutUrl);
      const optOutData = optOutResponse.data;
      const emailEventsChart = rawData.reduce((acc, event) => {
        let key;
        switch (event.eventType) {
          case 'Click':
            key = 'Clicks';
            break;
          case 'Open':
            key = 'Opens';
            break;
          case 'Bounce':
            key = 'Bounce';
            break;
          case 'Complaint':
            key = 'Complaints';
            break;
          case 'Send':
            key = 'Sent';
            break;
          case 'Delivery':
            key = 'Delivered';
            break;
          default:
            key = event.eventType;
        }

        acc[key] = {
          counts: event.timeSeries.map(data => data.count),
          timePeriods: event.timeSeries.map(data => data.timePeriod),
          total: event.total
        };
        return acc;
      }, {});

      emailEventsChart['Opt outs'] = {
        counts: optOutData.data.map(data => data.count),
        timePeriods: optOutData.data.map(data => data.timePeriod),
        total: optOutData.total
      };
      setter(emailEventsChart);
    } catch (error) {
      const message = error.response?.data?.message || 'Error fetching data';
      toast.error(message);
    } finally {
      setterFetching(false);
    }
  };

  const fetchEmailUsage = useCallback(() => {
    if (id && emailUsagePeriod) {
      fetchData(
        `/campaign-events?senderId=${id}&period=${emailUsagePeriod.toLowerCase()}`,
        `/campaign-events/opt-out?senderId=${id}&period=${emailUsagePeriod.toLowerCase()}`,
        setEmailUsage,
        setIsEmailUsageFetching
      );
    }
  }, [id, emailUsagePeriod]);

  const fetchEmailEvent = useCallback(() => {
    if (id && emailEventPeriod) {
      fetchData(
        `/campaign-events?senderId=${id}&period=${emailEventPeriod.toLowerCase()}`,
        `/campaign-events/opt-out?senderId=${id}&period=${emailEventPeriod.toLowerCase()}`,
        setEmailEvent,
        setIsEmailEventFetching
      );
    }
  }, [id, emailEventPeriod]);

  useEffect(() => {
    fetchEmailUsage();
  }, [fetchEmailUsage]);

  useEffect(() => {
    fetchEmailEvent();
  }, [fetchEmailEvent]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <SentEmailVolume />
        </Col>
        <Col md={12}>
          <DailyEmailUsage />
        </Col>
        <Col md={12}>
          <EmailEvent
            onPeriodChange={setEmailEventPeriod}
            chartData={emailEvent}
            period={emailEventPeriod}
            isEmailEventFetching={isEmailEventFetching}
            className="mb-3"
          />
        </Col>
        <Col md={12}>
          <SendingStatistics onPeriodChange={setEmailUsagePeriod} />
        </Col>
        <Col md={12} xxl={6}>
          <SendEmailEvent
            chartData={emailUsage}
            period={emailUsagePeriod}
            isEmailUsageFetching={isEmailUsageFetching}
            className="mb-3"
          />
        </Col>
        <Col md={12} xxl={6}>
          <OpenEmailEvent
            chartData={emailUsage}
            period={emailUsagePeriod}
            isEmailUsageFetching={isEmailUsageFetching}
            className="mb-3"
          />
        </Col>
        <Col md={12} xxl={6}>
          <ClickEmailEvent
            chartData={emailUsage}
            period={emailUsagePeriod}
            isEmailUsageFetching={isEmailUsageFetching}
            className="mb-3"
          />
        </Col>
        <Col md={12} xxl={6}>
          <DeliveredEmailEvent
            chartData={emailUsage}
            period={emailUsagePeriod}
            isEmailUsageFetching={isEmailUsageFetching}
            className="mb-3"
          />
        </Col>
        <Col md={12} xxl={6}>
          <ComplaintsEmailEvent
            chartData={emailUsage}
            period={emailUsagePeriod}
            isEmailUsageFetching={isEmailUsageFetching}
            className="mb-3"
          />
        </Col>
        <Col md={12} xxl={6}>
          <BounceEmailEvent
            chartData={emailUsage}
            period={emailUsagePeriod}
            isEmailUsageFetching={isEmailUsageFetching}
            className="mb-3"
          />
        </Col>
        <Col md={12} xxl={6}>
          <OptOutEmailEvent
            chartData={emailUsage}
            period={emailUsagePeriod}
            isEmailUsageFetching={isEmailUsageFetching}
            className="mb-3"
          />
        </Col>
      </Row>
    </>
  );
};

export default SenderDetailPage;
