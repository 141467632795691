import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import Divider from 'components/common/Divider';
import WizardInput from 'components/wizard/WizardInput';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { OrganizationStatusType, EmailSendingStatus } from 'data/common';
import { businessTypes } from 'data/businessTypes';
import bgGrey from 'assets/img/icons/grey-bg.png';
import userAvatar from 'assets/img/icons/user.png';
import { FaCamera } from 'react-icons/fa';
import Avatar from 'components/common/Avatar';
import Background from 'components/common/Background';
import { useAppSelector } from 'redux/store';
import { selectUser } from 'redux/reducer/auth';

const OrganizationModal = ({
  showOrganizationModal,
  setShowOrganizationModal,
  fetchOrganizations,
  organizationDetail,
  mode
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      emailSendingEnabled: false,
      textSendingEnabled: false,
      sendingLimit: 0,
      emailSendingStatus: '',
      tierPlan: '',
      status: '',
      businessName: '',
      businessAddress: '',
      avatar: '',
      companyLogo: '',
      businessType: '',
      state: '',
      zipCode: '',
      city: '',
      website: ''
    }
  });

  const user = useAppSelector(selectUser);
  const [isOtherSelected, setIsOtherSelected] = React.useState(false);
  const handleBusinessTypeChange = event => {
    setIsOtherSelected(event.target.value === 'Other');
  };

  const [updateOrganization, setUpdateOrganization] = React.useState(
    organizationDetail || {}
  );
  const [companyLogo, setCompanyLogo] = React.useState(
    organizationDetail.companyLogo || bgGrey
  );
  const [avatar, setAvatar] = React.useState(
    organizationDetail.avatar || userAvatar
  );

  const [loading, setLoading] = useState(false);
  const handleCompanyLogoUpload = event => {
    const file = event.target.files[0];
    setUpdateOrganization({
      ...updateOrganization,
      companyLogo: file
    });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCompanyLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarUpload = event => {
    const file = event.target.files[0];
    setUpdateOrganization({
      ...updateOrganization,
      avatar: file
    });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    reset();
  }, [showOrganizationModal]);

  const handleUpdateOrganization = async data => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (avatar) {
        formData.append('avatar', updateOrganization.avatar);
      }

      if (companyLogo) {
        formData.append('companyLogo', updateOrganization.companyLogo);
      }
      const businessType =
        data.businessType === 'Other' ? 'Other' : data.businessType;
      formData.append('name', data.name);
      formData.append('emailSendingEnabled', data.emailSendingEnabled);
      formData.append('textSendingEnabled', data.textSendingEnabled);
      formData.append('sendingLimit', data.sendingLimit);
      formData.append('emailSendingStatus', data.emailSendingStatus);
      formData.append('tierPlan', data.tierPlan);
      formData.append('status', data.status);
      formData.append('businessName', data.businessName);
      formData.append('businessAddress', data.businessAddress);
      formData.append('businessType', businessType);
      formData.append('state', data.state);
      formData.append('zipCode', data.zipCode);
      formData.append('city', data.city);
      formData.append('website', data.website);

      await axiosClient.put(
        `/organizations/${organizationDetail.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      fetchOrganizations();
      toast.success('Organization updated successfully');
      setShowOrganizationModal(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || 'Organization update failed'
      );
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = data => {
    if (mode === 'EDIT') {
      handleUpdateOrganization(data);
    }
  };

  useEffect(() => {
    if (organizationDetail && mode !== 'ADD') {
      const transformData = {
        ...organizationDetail
      };

      Object.keys(transformData).forEach(item => {
        setValue(item, transformData[item]);
      });
    }
  }, [organizationDetail]);

  useEffect(() => {
    if (organizationDetail && mode !== 'ADD') {
      const transformData = {
        ...organizationDetail
      };

      if (!businessTypes.includes(organizationDetail.businessType)) {
        transformData.businessType = 'Other';
        transformData.otherBusinessType = organizationDetail.businessType;
        setIsOtherSelected(true);
      } else {
        setIsOtherSelected(false);
      }

      Object.keys(transformData).forEach(item => {
        setValue(item, transformData[item]);
      });
    }
  }, [organizationDetail]);

  return (
    <Modal
      show={showOrganizationModal}
      onHide={() => {
        setShowOrganizationModal(false);
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {mode === 'EDIT' ? 'Update Organization' : 'Add Organization'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Col>
              <div className="position-relative min-vh-25 mb-7 p-0">
                <div
                  className="col-12 position-relative"
                  style={{ height: 270 }}
                >
                  <div
                    className="position-absolute bg-200 z-1 p-2 rounded-circle cursor-pointer"
                    style={{ bottom: 12, right: 12 }}
                    onClick={() =>
                      document.getElementById('companyLogo').click()
                    }
                  >
                    <FaCamera className="fs-7" />
                  </div>
                  <input
                    type="file"
                    id="companyLogo"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleCompanyLogoUpload}
                  />
                  <Background
                    image={companyLogo}
                    className="rounded-3 rounded-bottom-0 "
                  />
                </div>

                <div className="avatar-profile">
                  <div className="position-relative">
                    <div
                      className="position-absolute bg-200 z-1 p-2 rounded-circle cursor-pointer"
                      style={{ bottom: 12, right: 12 }}
                      onClick={() => document.getElementById('avatar').click()}
                    >
                      <FaCamera className="fs-7" />
                    </div>
                    <input
                      type="file"
                      id="avatar"
                      style={{ display: 'none' }}
                      accept="image/*"
                      onChange={handleAvatarUpload}
                    />
                    <Avatar
                      size="5xl"
                      src={avatar}
                      mediaClass="img-thumbnail shadow-sm"
                    />
                  </div>
                </div>
              </div>
              <WizardInput
                label="Name"
                name="name"
                errors={errors}
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                  ...register('name', {
                    required: 'Name is required'
                  })
                }}
              />

              <WizardInput
                type="text"
                label="Website"
                name="website"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('website', {
                    required: 'Website is required',
                    pattern: {
                      value: /^(https):\/\/[^ "]+$/i,
                      message: 'Website URL must be valid'
                    }
                  })
                }}
              />
              <WizardInput
                type="text"
                label="Business name"
                name="businessName"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('businessName', {
                    required: 'Business name is required'
                  })
                }}
              />
              <WizardInput
                type="textarea"
                label="Business address"
                name="businessAddress"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('businessAddress', {
                    required: 'Business address is required'
                  })
                }}
              />
              <Row>
                <WizardInput
                  type="select"
                  label="Business type"
                  name="businessType"
                  placeholder="Select business type..."
                  errors={errors}
                  options={businessTypes}
                  formGroupProps={{
                    className: `mb-3 ${isOtherSelected ? 'w-50' : ''}`
                  }}
                  formControlProps={{
                    ...register('businessType', {
                      required: 'Business type is required'
                    }),
                    onChange: e => {
                      handleBusinessTypeChange(e);
                    }
                  }}
                />
                {isOtherSelected && (
                  <WizardInput
                    type="text"
                    label="Specify other business type"
                    name="otherBusinessType"
                    errors={errors}
                    formGroupProps={{
                      className: `mb-3 ${isOtherSelected ? 'w-50' : ''}`
                    }}
                    formControlProps={{
                      className: 'input-spin-none',
                      ...register('otherBusinessType', {
                        required: 'Please specify your business type'
                      })
                    }}
                  />
                )}
              </Row>
              <WizardInput
                type="text"
                label="State"
                name="state"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('state', {
                    required: 'State is required'
                  })
                }}
              />
              <WizardInput
                type="text"
                label="Zip code"
                name="zipCode"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('zipCode', {
                    required: 'Zip code is required',
                    pattern: {
                      value: /^\d{5}(-\d{4})?$/,
                      message: 'Zip code must be valid'
                    }
                  })
                }}
              />
              <WizardInput
                type="text"
                label="City"
                name="city"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('city', {
                    required: 'City is required'
                  })
                }}
              />

              <WizardInput
                type="select"
                label="Tier plan"
                name="tierPlan"
                placeholder="Select tier plan..."
                errors={errors}
                options={['Tier 1', 'Tier 2', 'Tier 3']}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('tierPlan', {
                    required: 'Tier plan is required'
                  }),
                  disabled: !user.isAdmin,
                  onChange: e => {
                    const newTier = e.target.value;
                    if (newTier === 'Tier 1') {
                      setValue('sendingLimit', 600000);
                    } else if (newTier === 'Tier 2') {
                      setValue('sendingLimit', 100000);
                    } else {
                      setValue('sendingLimit', 25000);
                    }
                  }
                }}
              />
              <WizardInput
                type="number"
                label="Sending limit"
                name="sendingLimit"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('sendingLimit', {
                    required: 'Sending limit is required'
                  }),
                  disabled: !user.isAdmin
                }}
              />
              <WizardInput
                type="select"
                label="Email sending status"
                name="emailSendingStatus"
                placeholder="Select email sending status"
                errors={errors}
                options={Object.values(EmailSendingStatus)}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('emailSendingStatus', {
                    required: 'Email sending status is required'
                  }),
                  disabled: !user.isAdmin
                }}
              />
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Form.Group controlId="status" className="mb-0">
                    <Form.Label className="mb-0 text-700">Status</Form.Label>
                    <div className="d-flex gap-4">
                      <Form.Check
                        type="radio"
                        label="Active"
                        id="statusActive"
                        {...field}
                        value={OrganizationStatusType.ACTIVE}
                        checked={field.value === OrganizationStatusType.ACTIVE}
                        className="mb-1"
                        disabled={!user.isAdmin}
                      />
                      <Form.Check
                        type="radio"
                        label="Disabled"
                        id="statusDisabled"
                        {...field}
                        value={OrganizationStatusType.DISABLED}
                        checked={
                          field.value === OrganizationStatusType.DISABLED
                        }
                        className="mb-0"
                        disabled={!user.isAdmin}
                      />
                    </div>
                  </Form.Group>
                )}
              />
              <Controller
                name="emailSendingEnabled"
                control={control}
                render={({ field }) => (
                  <Form.Group controlId="emailSendingEnabled" className="mb-0">
                    <Form.Label className="mb-0 text-700">
                      Email sending enabled
                    </Form.Label>
                    <div className="d-flex gap-4">
                      <Form.Check
                        type="radio"
                        label="True"
                        id="emailEnabled"
                        {...field}
                        value={true}
                        checked={field.value === true}
                        className="mb-1"
                        onChange={() => field.onChange(true)}
                        disabled={!user.isAdmin}
                      />
                      <Form.Check
                        type="radio"
                        label="False"
                        id="emailDisabled"
                        {...field}
                        value={false}
                        checked={field.value === false}
                        className="mb-0"
                        onChange={() => field.onChange(false)}
                        disabled={!user.isAdmin}
                      />
                    </div>
                  </Form.Group>
                )}
              />
              <Controller
                name="textSendingEnabled"
                control={control}
                render={({ field }) => (
                  <Form.Group controlId="textSendingEnabled" className="mb-0">
                    <Form.Label className="mb-0 text-700">
                      Text sending enabled
                    </Form.Label>
                    <div className="d-flex gap-4">
                      <Form.Check
                        type="radio"
                        label="True"
                        id="textEnabled"
                        {...field}
                        value={true}
                        checked={field.value === true}
                        className="mb-1"
                        onChange={() => field.onChange(true)}
                        disabled={!user.isAdmin}
                      />
                      <Form.Check
                        type="radio"
                        label="False"
                        id="textDisabled"
                        {...field}
                        value={false}
                        checked={field.value === false}
                        className="mb-0"
                        onChange={() => field.onChange(false)}
                        disabled={!user.isAdmin}
                      />
                    </div>
                  </Form.Group>
                )}
              />
            </Col>
          </Form>
        )}
      </Modal.Body>
      <Divider />
      <div className="col-12 d-flex flex-column gap-3 justify-content-center align-items-center px-4 mb-3">
        <Button
          disabled={loading}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

OrganizationModal.propTypes = {
  showOrganizationModal: PropTypes.bool,
  setShowOrganizationModal: PropTypes.func,
  fetchOrganizations: PropTypes.func,
  organizationDetail: PropTypes.object,
  mode: PropTypes.oneOf(['VIEW', 'ADD', 'EDIT'])
};

export default OrganizationModal;
