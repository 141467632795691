import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const DeleteContactModal = ({
  showDeleteContactModal,
  setShowDeleteContactModal,
  deletingContactIds,
  setDeletingContactIds,
  fetchContacts
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteContacts = async ids => {
    setIsLoading(true);
    try {
      await axiosClient.delete(`/contacts/${ids.join(',')}`);
      fetchContacts();
      toast.success('Contacts deleted successfully');
      setIsLoading(false);
      setShowDeleteContactModal(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to delete contact');
      }
    }
  };

  return (
    <Modal
      show={showDeleteContactModal}
      onHide={() => {
        setShowDeleteContactModal(false);
        setDeletingContactIds([]);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Contact{deletingContactIds.length > 1 ? 's' : ''}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <div className="d-flex gap-2">
            <span>
              Are you sure you want to delete{' '}
              <b>
                {deletingContactIds.length} contact
                {deletingContactIds.length > 1 ? 's' : ''}
              </b>
              ?
            </span>
          </div>
        )}
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button
          variant="secondary"
          onClick={() => {
            setShowDeleteContactModal(false);
            setDeletingContactIds([]);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={isLoading}
          onClick={() => {
            handleDeleteContacts(deletingContactIds);
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

DeleteContactModal.propTypes = {
  showDeleteContactModal: PropTypes.bool.isRequired,
  setShowDeleteContactModal: PropTypes.func.isRequired,
  deletingContactIds: PropTypes.array.isRequired,
  setDeletingContactIds: PropTypes.func.isRequired,
  fetchContacts: PropTypes.func.isRequired
};

export default DeleteContactModal;
