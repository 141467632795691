import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  setOrganization,
  selectToken,
  selectIsLoading
} from 'redux/reducer/auth';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import defaultAvatar from 'assets/img/avatar/default.png';
import './styles.scss';

const OrganizationSelection = ({ organizations }) => {
  const navigate = useNavigate();
  const token = useAppSelector(selectToken);
  const [selectedOrg, setSelectedOrg] = useState();
  const isLoading = useAppSelector(selectIsLoading);

  const SetOrg = async (userId, organizationId) => {
    await dispatch(setOrganization({ userId, organizationId }));
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      navigate('/');
      toast.success(`Logged in as ${selectedOrg?.organization?.name}`, {
        theme: 'colored'
      });
    }
  }, [token]);

  if (isLoading) {
    return (
      <Row className="justify-content-center mb-3 g-3">
        <Col lg={4} className="d-flex justify-content-center">
          <Lottie
            animationData={infiniteLoop}
            loop={true}
            style={{ height: '120px', width: '120px' }}
          />
        </Col>
        <h3 className="text-center">Loading...</h3>
      </Row>
    );
  }

  return (
    <Row
      className="g-5 justify-content-center mx-auto "
      style={{ maxWidth: '80%' }}
    >
      {organizations?.map(org => (
        <Col
          key={org.id}
          xs={12}
          sm={6}
          md={4}
          xxl={3}
          className="d-flex justify-content-center"
        >
          <Card
            className="text-center card-hover"
            onClick={() => {
              SetOrg(org.userId, org.organizationId);
              setSelectedOrg(org);
            }}
            style={{
              width: '250px',
              margin: 'auto',
              borderRadius: '10px',
              cursor: 'pointer'
            }}
          >
            <Card.Body>
              <Card.Text className="mb-3 fs-11">
                {org.userId === org.organization.ownerId ? 'Owner' : org.role}
              </Card.Text>
              <Card.Img
                variant="top"
                src={org.organization.avatar || defaultAvatar}
                className="rounded-circle mb-3"
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover'
                }}
              />
              <Card.Text>{org.organization.name}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default OrganizationSelection;

OrganizationSelection.propTypes = {
  organizations: PropTypes.array
};
