import AddSenderModal from 'components/senders/AddSenderModal';
import DeleteSenderModal from 'components/senders/DeleteSenderModal';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { AiFillDelete } from 'react-icons/ai';
import {
  FaCheckSquare,
  FaMinusSquare,
  FaRegSquare,
  FaPaperPlane
} from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'redux/store';
import {
  selectIsLoading,
  selectOrganization,
  selectUser
} from 'redux/reducer/auth';
import Divider from 'components/common/Divider';
import { OrganizationRole } from 'data/common';

const SendersPage = () => {
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const isLoadingUser = useAppSelector(selectIsLoading);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const [currentSenders, setCurrentSenders] = useState([]);
  const [showAddSenderModal, setShowAddSenderModal] = useState(false);

  const [showDeleteSenderModal, setShowDeleteSenderModal] = useState(false);
  const [deletingSenders, setDeletingSenders] = useState([]);

  const [step, setStep] = useState(null);

  const [newSender, setNewSender] = useState({
    username: '',
    email: ''
  });
  const [isLoadingAddSender, setIsLoadingAddSender] = useState(false);

  const [verifyingEmail, setVerifyingEmail] = useState();
  const [verifyingDomain, setVerifyingDomain] = useState();

  const selectedSendersCount = currentSenders.filter(
    item => item.selected
  ).length;

  const onSenderClick = index => {
    const updatedSenders = [...currentSenders];
    updatedSenders[index].selected = !updatedSenders[index].selected;
    setCurrentSenders(updatedSenders);
  };

  const onHeaderCheckBoxClick = () => {
    if (selectedSendersCount === currentSenders.length) {
      setCurrentSenders(
        currentSenders.map(sender => ({
          ...sender,
          selected: false
        }))
      );
    } else {
      setCurrentSenders(
        currentSenders.map(sender => ({
          ...sender,
          selected: true
        }))
      );
    }
  };

  const fetchSenders = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get('/email-account');
      setCurrentSenders(response.data);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch senders');
    }
    setIsFetching(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteSenderModal(false);
    setDeletingSenders([]);
  };

  const handleSubmitDeleteModal = async () => {
    setIsLoading(true);

    try {
      await axiosClient.delete(
        `/email-account?senders=${decodeURIComponent(
          JSON.stringify(deletingSenders)
        )}`
      );
      fetchSenders();
      toast.success('Sender deleted successfully');
      setShowDeleteSenderModal(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to delete sender');
      }
    }
    setIsLoading(false);
  };

  const handleAddSender = () => {
    handleSubmit(async () => {
      setIsLoadingAddSender(true);

      try {
        await axiosClient.post('/email-account', newSender);
        fetchSenders();
        toast.success('Sender added successfully');
        setVerifyingEmail({
          name: newSender.email,
          isVerified: false
        });
        setVerifyingDomain({
          name: newSender.email.split('@')[1],
          isVerified: false
        });
        setShowAddSenderModal(false);

        setIsLoadingAddSender(false);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to add sender');
        }
        setIsLoadingAddSender(false);
      }
    })();
  };

  useEffect(() => {
    fetchSenders();
  }, []);

  useEffect(() => {
    if (user) {
      if (organization.ownerId !== user.id) {
        setStep(3);
        return;
      }
      if (user.tierPlan !== 'Tier 1') {
        if (user.hasSetUpFirstSender) {
          setStep(3);
        } else {
          setStep(2);
          setVerifyingDomain(
            currentSenders.find(item => item.type === 'Domain') || null
          );
          setVerifyingEmail(
            currentSenders.find(item => item.type === 'Email') || null
          );
        }
        return;
      }
      if (!user.sesSetupComplete) {
        setStep(1);
      } else if (user.sesSetupComplete && user.productionAccessStatus) {
        if (
          user.productionAccessStatus === 'active' &&
          user.hasSetUpFirstSender
        ) {
          setStep(3);
        } else {
          setStep(2);
          setVerifyingDomain(
            currentSenders.find(item => item.type === 'Domain') || null
          );
          setVerifyingEmail(
            currentSenders.find(item => item.type === 'Email') || null
          );
        }
      }
    }
  }, [user, currentSenders]);

  return isLoadingUser || isFetching ? (
    <div className="vh-100 w-100 d-flex">
      <Spinner animation="border" role="status" className="m-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <>
      {step !== 3 && (
        <div className="container">
          <div className="justify-content-center pt-6 row">
            <div className="col-xxl-5 col-lg-7 col-sm-10">
              <Card
                noValidate
                className="theme-wizard mb-5"
                style={{ minHeight: '200px' }}
              >
                <Card.Body className="d-flex fw-normal px-4 px-md-6 py-4">
                  {step === 1 && (
                    <>
                      <p className="m-auto">
                        Your account is being set up. We will notify you when
                        you can continue. <br />
                        Thank you for your patience.
                      </p>
                    </>
                  )}
                  {step === 2 && (
                    <div className="w-100 h-100">
                      {isLoadingAddSender ? (
                        <Row className="justify-content-center mb-3 g-3">
                          <Col lg={4} className="d-flex justify-content-center">
                            <Lottie
                              animationData={infiniteLoop}
                              loop={true}
                              style={{ height: '120px', width: '120px' }}
                            />
                          </Col>
                          <h3 className="text-center">Loading...</h3>
                        </Row>
                      ) : (
                        <>
                          {currentSenders.length === 0 ? (
                            <>
                              <h5 className="text-center mb-4">
                                Add first email sender
                              </h5>
                              <Form onSubmit={handleAddSender}>
                                <WizardInput
                                  label="Username*"
                                  name="username"
                                  errors={errors}
                                  formGroupProps={{ className: 'mb-3' }}
                                  formControlProps={{
                                    ...register('username', {
                                      required: 'Username is required'
                                    }),
                                    onChange: e => {
                                      setNewSender({
                                        ...newSender,
                                        username: e.target.value
                                      });
                                    }
                                  }}
                                />

                                <WizardInput
                                  type="email"
                                  errors={errors}
                                  label="Email*"
                                  name="email"
                                  formGroupProps={{ className: 'mb-3' }}
                                  formControlProps={{
                                    ...register('email', {
                                      required: 'Email field is required',
                                      pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: 'Email must be valid'
                                      }
                                    }),
                                    onChange: e => {
                                      setNewSender({
                                        ...newSender,
                                        email: e.target.value
                                      });
                                    }
                                  }}
                                />
                              </Form>
                              <div className="col-12 d-flex flex-column gap-3 justify-content-center align-items-center px-4 mb-3">
                                <Button
                                  disabled={isLoading}
                                  onClick={() => handleAddSender(false)}
                                  style={{ width: 'fit-content' }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </>
                          ) : verifyingDomain &&
                            verifyingDomain.isVerified &&
                            verifyingEmail &&
                            verifyingEmail.isVerified ? (
                            <p className="m-auto">
                              Thank you for verifying your email!
                              <br />
                              We are currently setting up your account, and you
                              will not be able to send emails just yet. You may
                              receive emails from our AWS partner regarding your
                              SES account setup, which you can safely ignore. We
                              will notify you via email once the process is
                              complete. Please note that this may take up to 24
                              hours. Thank you for your patience, and welcome to
                              our platform!
                            </p>
                          ) : (
                            <>
                              <p className="m-auto ">
                                Thank you for adding your first email sender!
                                <br />
                                To proceed, you will first receive an email from
                                our AWS partner to verify your email address.
                                Once verified, we will send you the DNS
                                instructions via email for the next step in the
                                setup process. You can track the verification
                                progress below. Thank you for your patience as
                                we guide you through this setup
                              </p>
                              <Divider />
                              {verifyingEmail && (
                                <div>
                                  Email <b>{verifyingEmail?.name}</b> is{' '}
                                  {verifyingEmail?.isVerified ? (
                                    <span className="text-success">
                                      verified
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      unverified
                                    </span>
                                  )}
                                </div>
                              )}
                              {verifyingDomain && (
                                <div>
                                  Domain <b>{verifyingDomain?.name}</b> is{' '}
                                  {verifyingDomain?.isVerified ? (
                                    <span className="text-success">
                                      verified
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      unverified
                                    </span>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      )}
      {step === 3 && (
        <Row className="g-3 mb-3">
          <Col md={12}>
            <Card className="overflow-hidden">
              <Card.Header className="col-12 border-bottom">
                {/* Container for Header and Buttons */}
                <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                  {/* Header with Icon */}
                  <h5 className="mb-3 mb-md-0">
                    <FaPaperPlane className="me-2" />
                    Email accounts
                  </h5>

                  {/* Buttons Container */}
                  {organization?.organizationRoles.find(
                    item => item.role === OrganizationRole.ADMIN
                  ) && (
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        variant="outline-danger"
                        className={`${
                          !selectedSendersCount ? 'disable' : ''
                        } mb-3 mb-md-0`}
                        disabled={!selectedSendersCount}
                        onClick={() => {
                          setDeletingSenders(
                            currentSenders.filter(sender => sender.selected)
                          );
                          setShowDeleteSenderModal(true);
                        }}
                      >
                        <AiFillDelete className="me-1" />
                        Delete
                      </Button>
                      <Button
                        variant="outline-primary"
                        onClick={() => setShowAddSenderModal(true)}
                        className="mb-3 mb-md-0"
                      >
                        <FaPlus className="me-1" />
                        Create
                      </Button>
                    </div>
                  )}
                </div>
              </Card.Header>
              <Card.Body>
                <Table
                  responsive
                  style={{
                    height:
                      isFetching || currentSenders.length < 0
                        ? 'calc(100vh - 210px)'
                        : ''
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className=" cursor-pointer"
                        onClick={onHeaderCheckBoxClick}
                      >
                        {selectedSendersCount > 0 ? (
                          selectedSendersCount === currentSenders.length ? (
                            <FaCheckSquare className="text-primary" />
                          ) : (
                            <FaMinusSquare className="text-primary" />
                          )
                        ) : (
                          <FaRegSquare />
                        )}
                      </th>
                      <th scope="col">Sender</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isFetching ? (
                      <tr>
                        <td
                          colSpan="12"
                          className="text-center align-items-center align-content-center"
                        >
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    ) : currentSenders.length > 0 ? (
                      currentSenders.map((sender, index) => (
                        <tr
                          key={`sender-${index}`}
                          onClick={() => onSenderClick(index)}
                          className={`${sender.selected ? 'bg-light' : ''}`}
                        >
                          <td>
                            {sender.selected ? (
                              <FaCheckSquare className="text-primary fs-9" />
                            ) : (
                              <FaRegSquare className="fs-9" />
                            )}
                          </td>
                          <td>
                            {sender.type === 'Email' ? (
                              <Link to={`/senders/${sender.id}`}>
                                {sender.name}
                              </Link>
                            ) : (
                              sender.name
                            )}
                          </td>
                          <td>{sender.type}</td>
                          <td
                            className={`d-flex align-items-center ${
                              sender.isVerified ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {sender.isVerified ? (
                              <GoCheckCircle className="fs-9 me-1" />
                            ) : (
                              <IoIosCloseCircleOutline className="fs-8 me-1" />
                            )}
                            {sender.isVerified ? 'Verified' : 'Unverified'}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="12"
                          className="text-center align-items-center align-content-center"
                        >
                          No senders found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <AddSenderModal
        showAddSenderModal={showAddSenderModal}
        setShowAddSenderModal={setShowAddSenderModal}
        fetchSenders={fetchSenders}
      />
      {deletingSenders && showDeleteSenderModal && (
        <DeleteSenderModal
          openModal={showDeleteSenderModal}
          handleCloseModal={handleCloseDeleteModal}
          data={deletingSenders}
          handleSubmitModal={handleSubmitDeleteModal}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default SendersPage;
