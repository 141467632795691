import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import './styles.scss';
import { FaPenToSquare, FaDeleteLeft } from 'react-icons/fa6';
import { operators, dateOperators } from 'data/common';
import dataTypes from 'data/dataTypes';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

const AND = 'AND';
const OR = 'OR';

const SegmentForm = ({
  fields,
  criteria,
  onCriteriaChange,
  criteriaError,
  editable = true
}) => {
  const [selectedField, setSelectedField] = useState('');
  const [operator, setOperator] = useState('');

  const [operatorOptions, setOperatorOptions] = useState(operators);
  const allOperator = {
    ...operators,
    ...dateOperators
  };

  const [field, setField] = useState('');
  const [value, setValue] = useState('');
  const [andOr, setAndOr] = useState(AND);

  const [fieldError, setFieldError] = useState('');
  const [operatorError, setOperatorError] = useState('');
  const [valueError, setValueError] = useState(null);

  const [message, setMessage] = useState('');

  const validate = () => {
    let validate = true;
    if (!field) {
      setFieldError('Field is required');
      validate = false;
    } else {
      setFieldError('');
    }

    if (!operator) {
      setOperatorError('Operator is required');
      validate = false;
    } else {
      setOperatorError('');
    }
    if (value === '' || value === null) {
      validate = false;
      setValueError('Value is required');
    } else {
      setValueError(null);
    }
    return validate;
  };
  const resetSegment = () => {
    setField('');
    setSelectedField('');
    setOperator('');
    setValue('');
    setAndOr(AND);
    setMessage('');
  };

  const onAddCondition = () => {
    if (!validate()) return;
    if (criteria[0] && !criteria[0].andOr) {
      onCriteriaChange([...criteria, { field, operator, value, andOr }]);
    } else {
      onCriteriaChange([{ field, operator, value }, ...criteria]);
    }
    resetSegment();
  };

  const onDelete = index => {
    const newCriteria = criteria.filter((_, i) => i !== index);
    onCriteriaChange(newCriteria);
  };

  const onEdit = index => {
    const newCriteria = criteria.filter((_, i) => i !== index);
    setField(criteria[index].field);
    setOperator(criteria[index].operator);
    setValue(criteria[index].value);
    onCriteriaChange(newCriteria);
  };

  const onOperatorChange = data => {
    setOperator(data);
    setValueError(null);
    let fromDate = new Date();
    let toDate = new Date();
    switch (data) {
      case 'today':
        setValue({
          fromDate: dayjs(fromDate).format('MM-DD-YYYY'),
          toDate: dayjs(toDate).format('MM-DD-YYYY')
        });
        break;
      case 'last7Days':
        fromDate.setDate(fromDate.getDate() - 7);
        setValue({
          fromDate: dayjs(fromDate).format('MM-DD-YYYY'),
          toDate: dayjs(toDate).format('MM-DD-YYYY')
        });
        break;
      case 'last30Days':
        fromDate.setDate(fromDate.getDate() - 30);
        setValue({
          fromDate: dayjs(fromDate).format('MM-DD-YYYY'),
          toDate: dayjs(toDate).format('MM-DD-YYYY')
        });
        break;
      case 'yearToDate':
        fromDate.setMonth(0);
        fromDate.setDate(1);
        setValue({
          fromDate: dayjs(fromDate).format('MM-DD-YYYY'),
          toDate: dayjs(toDate).format('MM-DD-YYYY')
        });
        break;
      case 'between':
        setValue({
          fromDate: dayjs(fromDate).format('MM-DD-YYYY'),
          toDate: dayjs(toDate).format('MM-DD-YYYY')
        });
        break;

      default:
        setValue(null);
        break;
    }
  };

  useEffect(() => {
    if (field) {
      const _selectedField = [
        ...fields.contactFields,
        ...fields.customFields
      ].find(item => item.fieldName === field);
      setSelectedField(_selectedField);
      const updatedOperatorOptions = { ...operators };
      switch (_selectedField.fieldType) {
        case dataTypes.BOOLEAN:
          setOperatorOptions({
            '=': operators['='],
            '!=': operators['!=']
          });

          break;
        case dataTypes.DATE:
          setOperatorOptions({
            '=': operators['='],
            '!=': operators['!='],
            ...dateOperators
          });
          break;
        case dataTypes.NUMBER:
          delete updatedOperatorOptions['contains'];
          setOperatorOptions(updatedOperatorOptions);
          break;

        default:
          setOperatorOptions(operators);
          break;
      }
    }
  }, [field]);

  return (
    <>
      <div className="criteria-container">
        {criteria &&
          criteria.map((_criteria, index) => (
            <div className="criteria" key={index}>
              {_criteria.andOr && !!index && (
                <button className="and-or-button active absolute">
                  {_criteria.andOr}
                </button>
              )}
              {editable && (
                <div className="action">
                  <FaPenToSquare
                    className="icon edit"
                    onClick={() => onEdit(index)}
                  />
                  <FaDeleteLeft
                    className="icon delete"
                    onClick={() => onDelete(index)}
                  />
                </div>
              )}
              <span className="text-capitalize">
                {_criteria.field?.replaceAll('_', ' ')}
              </span>{' '}
              <i className="text-lowercase">
                {allOperator[_criteria.operator]}
              </i>{' '}
              <strong>
                {typeof _criteria.value === 'object'
                  ? _criteria.operator === 'between' &&
                    `${_criteria.value.fromDate} and ${_criteria.value.toDate}`
                  : _criteria.value?.toString()}
              </strong>
            </div>
          ))}
      </div>
      {criteria && criteria.length > 0 && editable && (
        <div>
          <div className="vertical-line ms-5"></div>
          <div className="and-or-buttons">
            <div
              className={`and-or-button ${andOr === AND && 'active'}`}
              onClick={() => setAndOr(AND)}
            >
              AND
            </div>
            <div
              className={`and-or-button ${andOr === OR && 'active'}`}
              onClick={() => setAndOr(OR)}
            >
              OR
            </div>
          </div>
          <div className="vertical-line ms-5"></div>
        </div>
      )}
      {editable && (
        <>
          <div className="segment">
            <Row>
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Field name</Form.Label>
                <Dropdown
                  className={`segment-dropdown ${fieldError ? 'error' : ''}`}
                  onSelect={e => {
                    setField(e);
                    setOperator('');
                    setValue('');
                    setFieldError('');
                    setOperatorError('');
                    setValueError(null);
                  }}
                >
                  {field ? (
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {selectedField?.fieldName?.replaceAll(/_/g, ' ')}
                    </Dropdown.Toggle>
                  ) : (
                    <Dropdown.Toggle variant="falcon-default">
                      Select field
                    </Dropdown.Toggle>
                  )}

                  <Dropdown.Menu className="py-2">
                    <Dropdown.Header>Contact Fields</Dropdown.Header>
                    {fields.contactFields &&
                      fields.contactFields.map((field, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={field.fieldName}
                          className="text-capitalize"
                        >
                          {field?.fieldName?.replaceAll(/_/g, ' ')}
                        </Dropdown.Item>
                      ))}
                    {fields.customFields && (
                      <>
                        <Dropdown.Divider />
                        <Dropdown.Header>Custom Fields</Dropdown.Header>
                        {fields.customFields.map((field, index) => (
                          <Dropdown.Item
                            key={index}
                            eventKey={field.fieldName}
                            className="text-capitalize"
                          >
                            {field?.fieldName?.replaceAll(/_/g, ' ')}
                          </Dropdown.Item>
                        ))}
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {fieldError && (
                  <div className="invalid-feedback d-block">{fieldError}</div>
                )}
              </Col>
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Operator</Form.Label>
                <Dropdown
                  className={`segment-dropdown ${operatorError ? 'error' : ''}`}
                  onSelect={data => {
                    onOperatorChange(data);
                  }}
                >
                  <Dropdown.Toggle variant="falcon-default">
                    {operatorOptions[operator] || 'Select operator'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    {Object.keys(operatorOptions).map((key, index) => (
                      <Dropdown.Item key={index} eventKey={key}>
                        {operatorOptions[key]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {operatorError && (
                  <div className="invalid-feedback d-block">
                    {operatorError}
                  </div>
                )}
              </Col>
              <Col md={4} className="mb-3 mb-md-0">
                {!Object.keys(dateOperators).includes(operator) ? (
                  <>
                    <Form.Label>Value</Form.Label>
                    {selectedField &&
                    selectedField.fieldType &&
                    selectedField.fieldType.toLowerCase() ===
                      dataTypes.BOOLEAN ? (
                      <Form.Group>
                        <Row>
                          {[true, false].map(label => (
                            <Col md="6" key={`radio-${label}`}>
                              <Form.Check
                                type="radio"
                                className="text-capitalize"
                                label={label.toString()}
                                checked={value === label}
                                name={`value-${criteria?.length || 0}`}
                                onChange={() => {
                                  setValue(label);
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Form.Group>
                    ) : selectedField &&
                      selectedField.fieldType &&
                      selectedField.fieldType.toLowerCase() ===
                        dataTypes.DATE ? (
                      <DatePicker
                        className={`input-value ${valueError ? 'error' : ''}`}
                        selected={
                          value
                            ? new Date(value.replaceAll('-', '/'))
                            : new Date()
                        }
                        onChange={date => {
                          if (date) setValue(dayjs(date).format('MM-DD-YYYY'));
                        }}
                      />
                    ) : selectedField.options &&
                      selectedField.options.length > 0 ? (
                      <Form.Select
                        className={`input-value ${valueError ? 'error' : ''}`}
                        label="Value"
                        name={`value-${criteria?.length || 0}`}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        placeholder="Select value"
                      >
                        <option value="">Select value</option>
                        {selectedField.options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Select>
                    ) : (
                      <input
                        className={`input-value ${valueError ? 'error' : ''}`}
                        label="Value"
                        name={`value-${criteria?.length || 0}`}
                        type={selectedField?.fieldType?.toLowerCase() || 'text'}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        placeholder="Enter value"
                      />
                    )}
                  </>
                ) : operator === dateOperators.between.toLowerCase() ? (
                  <Row>
                    <Col md="6">
                      <Form.Label>From date</Form.Label>
                      <DatePicker
                        className={`input-value ${valueError ? 'error' : ''}`}
                        selected={
                          value.fromDate
                            ? new Date(value.fromDate.replaceAll('-', '/'))
                            : new Date()
                        }
                        onChange={date => {
                          if (!date) return;
                          setValue({
                            ...value,
                            fromDate: dayjs(date).format('MM-DD-YYYY')
                          });
                        }}
                      />
                    </Col>
                    <Col md="6">
                      <Form.Label>To date</Form.Label>
                      <DatePicker
                        className={`input-value ${valueError ? 'error' : ''}`}
                        selected={
                          value.toDate
                            ? new Date(value.toDate.replaceAll('-', '/'))
                            : new Date()
                        }
                        onChange={date => {
                          if (!date) return;
                          if (
                            value.fromDate &&
                            dayjs(date).isBefore(dayjs(value.fromDate))
                          ) {
                            setValueError('To date must be after from date');
                            return;
                          }
                          setValue({
                            ...value,
                            toDate: dayjs(date).format('MM-DD-YYYY')
                          });
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  operator === 'specify' && (
                    <>
                      <Form.Label>Value</Form.Label>
                      <input
                        className={`input-value ${valueError ? 'error' : ''}`}
                        label="Value"
                        name={`value-${criteria?.length || 0}`}
                        type="number"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        placeholder="Enter specify the days"
                      />
                    </>
                  )
                )}
                {valueError && (
                  <div className="invalid-feedback d-block">{valueError}</div>
                )}
              </Col>
            </Row>
          </div>
          <div className="vertical-line ms-5"></div>
          <Button variant="outline-primary" onClick={onAddCondition}>
            Add condition
          </Button>
          <p className="mt-2 fs-10 text-danger">{message}</p>

          {criteriaError && !message && (
            <div className="invalid-feedback d-block">{criteriaError}</div>
          )}
        </>
      )}
    </>
  );
};

SegmentForm.propTypes = {
  fields: PropTypes.array,
  criteria: PropTypes.array,
  onCriteriaChange: PropTypes.func,
  loading: PropTypes.bool,
  getContactsByCriteria: PropTypes.func,
  criteriaError: PropTypes.string,
  editable: PropTypes.bool
};

export default SegmentForm;
