import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import axiosClient from 'services/axios';
import Section from 'components/common/Section';
import Logo from 'components/common/Logo';
import Lottie from 'lottie-react';
import celebration from 'components/wizard/lottie/celebration.json';
import warningLight from 'assets/img/animated-icons/warning-light.json';
import './styles.scss';

const OptOutPage = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get('id');

    if (!id) {
      setError('Id is missing');
      setLoading(false);
      return;
    }

    const verifyEmail = async () => {
      try {
        const response = await axiosClient.get(`/contacts/opt-out?id=${id}`);
        setMessage(response.data.message);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || 'Something went wrong');
        setLoading(false);
      }
    };

    verifyEmail();
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo />
          <Card className="theme-wizard text-center">
            <Card.Body className="p-5">
              <Row>
                <Col className="text-center">
                  {error ? (
                    <div className="wizard-lottie-error">
                      <Lottie animationData={warningLight} loop={true} />
                    </div>
                  ) : (
                    <div className="wizard-lottie-wrapper">
                      <div className="wizard-lottie mx-auto">
                        <Lottie animationData={celebration} loop={true} />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <p className="mt-4">
                {error ||
                  message ||
                  'Something went wrong. Please try again later.'}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default OptOutPage;
