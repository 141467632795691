import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';

const Login = ({ setIsShowListOrg, setOrganizations }) => (
  <Card>
    <Card.Body className="p-4 p-sm-5">
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Log in</h5>
        <p className="fs-10 text-600 mb-0">
          or <Link to="/register">Create an account</Link>
        </p>
      </Flex>
      <LoginForm
        setIsShowListOrg={setIsShowListOrg}
        setOrganizations={setOrganizations}
      />
    </Card.Body>
  </Card>
);

export default Login;

Login.propTypes = {
  setIsShowListOrg: PropTypes.func,
  setOrganizations: PropTypes.func
};
