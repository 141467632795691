import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from 'components/wizard/WizardInput';
import { Col, Row, Form, Button } from 'react-bootstrap'; // Moved Form import to react-bootstrap
import dataTypes from 'data/dataTypes';
import { useForm } from 'react-hook-form';
import { IoCloseCircle } from 'react-icons/io5';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

export const CustomFieldForm = ({ customFields, contact, setContact }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [disableAddButton, setDisableAddButton] = useState(false);

  const handleAddNewField = () => {
    handleSubmit(async () => {
      const _contact = { ...contact };
      _contact.customFields.push({
        fieldId: '',
        fieldType: '',
        value: ''
      });

      setContact(_contact);
    })();
  };

  useEffect(() => {
    if (
      contact.customFields[contact.customFields.length - 1] &&
      contact.customFields[contact.customFields.length - 1].fieldId === ''
    ) {
      setDisableAddButton(true);
    } else {
      setDisableAddButton(false);
    }
  }, [contact]);

  return (
    <Form onSubmit={handleAddNewField}>
      {contact.customFields.map((field, index) => (
        <Row key={`custom-field-${index}`} className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Field name</Form.Label>
              <Form.Select
                type="select"
                value={field.fieldId}
                onChange={e => {
                  const _contact = { ...contact };
                  const selectedField = customFields.find(
                    field => field.id === e.target.value
                  );
                  _contact.customFields[index] = {
                    fieldId: selectedField.id,
                    fieldType: selectedField.fieldType,
                    fieldName: selectedField.fieldName,
                    value:
                      selectedField.fieldType === dataTypes.DATE
                        ? dayjs(new Date()).format('MM-DD-YYYY')
                        : ''
                  };
                  setContact(_contact);
                }}
                name={`fieldName-${index}`}
              >
                <option value="">Select field</option>
                {customFields.map(option => (
                  <option
                    value={option.id}
                    key={option.id}
                    style={{
                      display:
                        contact.customFields.filter(
                          item => item.fieldName === option.fieldName
                        ).length > 0
                          ? 'none'
                          : 'block'
                    }}
                  >
                    {option.fieldName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors[`fieldName-${index}`]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={5}>
            {field.fieldId && (
              <>
                {field.fieldType.toLowerCase() === dataTypes.BOOLEAN ? (
                  <Form.Group>
                    <Form.Label>Value</Form.Label>

                    <Row>
                      {[true, false].map(label => (
                        <Col md="6" key={`radio-${label}`}>
                          <Form.Check
                            type="radio"
                            label={label.toString()}
                            checked={field.value === label}
                            name={`value-${index}`}
                            onChange={() => {
                              const _contact = { ...contact };
                              _contact.customFields[index].value = label;
                              setContact(_contact);
                            }}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Form.Group>
                ) : field.fieldType.toLowerCase() === dataTypes.DATE ? (
                  <>
                    <Form.Label>Value</Form.Label>
                    <DatePicker
                      className={`form-control ${
                        errors[`value-${index}`] ? 'is-invalid' : ''
                      }`}
                      selected={
                        field.value
                          ? new Date(field.value.replaceAll('-', '/'))
                          : new Date()
                      }
                      onChange={date => {
                        if (!date) return;
                        const _contact = { ...contact };
                        _contact.customFields[index].value =
                          dayjs(date).format('MM-DD-YYYY');
                        setContact(_contact);
                      }}
                    />
                    {errors[`value-${index}`] && (
                      <div className="invalid-feedback d-block">
                        {errors[`value-${index}`]?.message}
                      </div>
                    )}
                  </>
                ) : (
                  <WizardInput
                    label="Value"
                    name={`value-${index}`}
                    type={field.fieldType.toLowerCase() || 'text'}
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register(`value-${index}`, {
                        required: 'Please enter a value'
                      }),
                      value: field.value,
                      onChange: e => {
                        const _contact = { ...contact };
                        _contact.customFields[index].value = e.target.value;
                        setContact(_contact);
                      }
                    }}
                  />
                )}
              </>
            )}
          </Col>
          {field.fieldId && (
            <Col
              md={1}
              className="ps-0"
              onClick={() => {
                const _contact = { ...contact };
                _contact.customFields.splice(index, 1);
                setContact(_contact);
              }}
            >
              <IoCloseCircle className="icon-delete" />
            </Col>
          )}
        </Row>
      ))}

      <Button disabled={disableAddButton} onClick={handleAddNewField}>
        Add new field
      </Button>
    </Form>
  );
};

CustomFieldForm.propTypes = {
  customFields: PropTypes.array.isRequired,
  contact: PropTypes.object.isRequired,
  setContact: PropTypes.func.isRequired
};

export default CustomFieldForm;
