import React from 'react';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import { Link } from 'react-router-dom';

const Success = () => {
  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Your account is all set!</h4>
          <p className="fs-9">Now you can access to your account</p>
          <Link to="/">
            <Button color="primary" className="px-5 my-3">
              Go to Home page
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
