import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const DeleteCustomFieldModal = ({
  showDeleteCustomFieldModal,
  setShowDeleteCustomFieldModal,
  deletingCustomFieldIds,
  setDeletingCustomFieldIds,
  fetchCustomFields
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteCustomFields = async ids => {
    setIsLoading(true);
    try {
      await axiosClient.delete(`/custom-fields/${ids.join(',')}`);
      fetchCustomFields();
      toast.success('Custom fields deleted successfully');
      setIsLoading(false);
      setShowDeleteCustomFieldModal(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Modal
      show={showDeleteCustomFieldModal}
      onHide={() => {
        setShowDeleteCustomFieldModal(false);
        setDeletingCustomFieldIds([]);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Custom Field{deletingCustomFieldIds.length > 1 ? 's' : ''}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <div className="d-flex gap-2">
            <span>
              Are you sure you want to delete{' '}
              <b>
                {deletingCustomFieldIds.length} custom field
                {deletingCustomFieldIds.length > 1 ? 's' : ''}
              </b>
              ?
            </span>
          </div>
        )}
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button
          variant="secondary"
          onClick={() => {
            setShowDeleteCustomFieldModal(false);
            setDeletingCustomFieldIds([]);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={isLoading}
          onClick={() => {
            handleDeleteCustomFields(deletingCustomFieldIds);
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

DeleteCustomFieldModal.propTypes = {
  showDeleteCustomFieldModal: PropTypes.bool.isRequired,
  setShowDeleteCustomFieldModal: PropTypes.func.isRequired,
  deletingCustomFieldIds: PropTypes.array.isRequired,
  setDeletingCustomFieldIds: PropTypes.func.isRequired,
  fetchCustomFields: PropTypes.func.isRequired
};

export default DeleteCustomFieldModal;
