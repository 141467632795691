import WizardInput from 'components/wizard/WizardInput';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const EditTextTemplateModal = ({
  openModal,
  handleCloseModal,
  data,
  handleSubmitModal,
  isLoading
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: data.name,
      content: data.content,
      type: 'text'
    }
  });

  const onSubmit = formData => {
    handleSubmitModal(formData);
  };

  return (
    <Modal
      show={openModal}
      onHide={() => handleCloseModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="col-12 d-flex flex-wrap gap-2 justify-content-between border-bottom"
      >
        <Modal.Title as="h5" id="contained-modal-title-vcenter">
          Update Text Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
          <WizardInput
            label="Title"
            name="name"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('name', {
                required: 'Title is required'
              }),
              placeholder: 'Enter template title',

              value: watch('name')
            }}
          />
          <WizardInput
            label="Content"
            name="content"
            errors={errors}
            type="textarea"
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('content', {
                required: 'Content is required'
              }),
              placeholder: 'Enter template content',
              rows: 5,
              value: watch('content')
            }}
          />
          <Button
            className="mt-4 mb-2 mx-auto"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

EditTextTemplateModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmitModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    content: PropTypes.string
  }).isRequired
};

export default EditTextTemplateModal;
