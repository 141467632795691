import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import Login from 'components/authentication/simple/Login';
import OrganizationSelection from './organizationSelection';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SimpleLogin = () => {
  const [organizations, setOrganizations] = useState([]);
  const [isShowListOrg, setIsShowListOrg] = useState(false);

  return (
    <>
      {isShowListOrg ? (
        <Col>
          <OrganizationSelection organizations={organizations} />
          <Link
            to="/login"
            className="d-flex mx-auto mt-4 text-center fs-10"
            onClick={() => {
              setIsShowListOrg(false);
            }}
            style={{ width: 'fit-content' }}
          >
            Back to login
          </Link>
        </Col>
      ) : (
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4 mx-auto">
          <Login
            setIsShowListOrg={setIsShowListOrg}
            setOrganizations={setOrganizations}
          />
        </Col>
      )}
    </>
  );
};

export default SimpleLogin;

SimpleLogin.propTypes = {
  organizations: PropTypes.array
};
