export const categories = [
  'Marketing',
  'Transactional',
  'Welcome',
  'Reciept',
  'Event',
  'Newsletter',
  'Travel',
  'Entertainment'
];
