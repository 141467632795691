import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const DeleteCampaignModal = ({
  showDeleteCampaignModal,
  setShowDeleteCampaignModal,
  deletingCampaignIds,
  setDeletingCampaignIds,
  fetchCampaigns
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteCampaigns = async ids => {
    setIsLoading(true);
    try {
      await axiosClient.delete(`/campaigns/${ids.join(',')}`);
      toast.success('Campaigns deleted successfully');
      fetchCampaigns();
      setIsLoading(false);
      setShowDeleteCampaignModal(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to delete campaign');
      }
    }
  };

  return (
    <Modal
      show={showDeleteCampaignModal}
      onHide={() => {
        setShowDeleteCampaignModal(false);
        setDeletingCampaignIds([]);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Campaign{deletingCampaignIds.length > 1 ? 's' : ''}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <div className="d-flex gap-2">
            <span>
              Are you sure you want to delete{' '}
              <b>
                {deletingCampaignIds.length} campaign
                {deletingCampaignIds.length > 1 ? 's' : ''}
              </b>
              ?
            </span>
          </div>
        )}
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button
          variant="secondary"
          onClick={() => {
            setShowDeleteCampaignModal(false);
            setDeletingCampaignIds([]);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={isLoading}
          onClick={() => {
            handleDeleteCampaigns(deletingCampaignIds);
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

DeleteCampaignModal.propTypes = {
  showDeleteCampaignModal: PropTypes.bool.isRequired,
  setShowDeleteCampaignModal: PropTypes.func.isRequired,
  deletingCampaignIds: PropTypes.array.isRequired,
  setDeletingCampaignIds: PropTypes.func.isRequired,
  fetchCampaigns: PropTypes.func.isRequired
};

export default DeleteCampaignModal;
