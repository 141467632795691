import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import AppProvider from 'providers/AppProvider';
import 'helpers/initFA';
import App from 'App';
import store from 'redux/store';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AppProvider>
      <App />
    </AppProvider>
  </Provider>
  // </React.StrictMode>
);
