import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { selectUser } from 'redux/reducer/auth';

const AdminRoute = () => {
  const user = useAppSelector(selectUser);

  return user && user.isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoute;
