import WizardInput from 'components/wizard/WizardInput';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import grapesjs from 'grapesjs';
import newsLetter from 'grapesjs-preset-newsletter-v2';
import 'grapesjs/dist/css/grapes.min.css';
import { categories } from 'data/categories';
import { useAppSelector } from 'redux/store';
import { selectUser } from 'redux/reducer/auth';

grapesjs.plugins.add('gjs-preset-newsletter', newsLetter);

const UpdateTemplatesPage = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();
  const { id } = useParams();
  const user = useAppSelector(selectUser);

  const editorRef = useRef(null);
  const [template, setTemplate] = useState({
    name: '',
    content: null,
    category: ''
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (editorRef.current) {
      setLoading(true);
      const html = editorRef.current.getHtml();
      const css = editorRef.current.getCss();
      const components = editorRef.current.getComponents();
      const styles = editorRef.current.getStyle();
      const formData = new FormData();
      formData.append('name', template.name);
      formData.append('category', template.category);
      formData.append('isPublic', template.isPublic);

      const content = {
        html,
        css,
        components,
        styles
      };
      const contentBlob = new Blob([JSON.stringify(content)], {
        type: 'application/json'
      });
      formData.append('content', contentBlob, 'content.json');
      try {
        await axiosClient.put(`/templates/email/${id}`, formData);
        toast.success('Template updated successfully');
        if (user && user.isAdmin) {
          navigate('/templates/email-template');
        } else {
          navigate('/templates/custom-email-template');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to update template');
        }
      }
    }
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      setLoading(true);
      try {
        const response = await axiosClient.get(`/templates/${id}`);
        setTemplate(response.data);
        setValue('name', response.data.name);
        setValue('category', response.data.category);
      } catch (error) {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to fetch template');
        }
      }
      setLoading(false);
    };
    if (id) {
      fetchTemplate();
    }
  }, [id]);

  useEffect(() => {
    if (template && template.content) {
      editorRef.current = grapesjs.init({
        container: '#gjs1',
        plugins: ['gjs-preset-newsletter'],
        pluginsOpts: {
          'gjs-preset-newsletter': {
            inlineCss: true
          }
        }
      });
      editorRef.current.on('load', () => {
        editorRef.current.runCommand('core:canvas-clear');
        editorRef.current.addComponents(template.content.html);
        editorRef.current.addStyle(template.content.css);

        console.log('Template loaded into editor');
      });
    }
  }, [template.id]);
  return (
    <Card>
      <Card.Header className="col-12 d-flex flex-wrap gap-2 justify-content-between border-bottom">
        <Card.Title as="h5" id="contained-modal-title-vcenter">
          Update email template
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
          <WizardInput
            label="Template name"
            name="name"
            errors={errors}
            formGroupProps={{ className: 'col-6 mb-3' }}
            formControlProps={{
              ...register('name', {
                required: 'Template name is required'
              }),
              onChange: e => {
                setTemplate({
                  ...template,
                  name: e.target.value
                });
              },
              value: template.name
            }}
          />
          <Form.Group className="col-6 mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Select
              type="select"
              value={template.category}
              {...register('category', { required: 'Category is required' })}
              isInvalid={!!errors.category}
              onChange={e => {
                setTemplate({
                  ...template,
                  category: e.target.value
                });
              }}
              name="category"
            >
              <option value="">Select category</option>
              {categories.map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors.category && (
              <div className="invalid-feedback d-block">
                {errors.category.message}
              </div>
            )}
          </Form.Group>
          <Form.Label>Content</Form.Label>
          <div
            id="gjs1"
            style={{ height: '100vh', border: '1px solid #ddd' }}
          ></div>
          {user && user.isAdmin && (
            <Form.Group className="col-6 mt-3">
              <Form.Check type="checkbox" id="isPublic" className="mb-0">
                <Form.Check.Input
                  type="checkbox"
                  name="isPublic"
                  checked={template.isPublic}
                  onChange={e =>
                    setTemplate({
                      ...template,
                      isPublic: e.target.checked
                    })
                  }
                />
                <Form.Check.Label className="mb-0 text-700">
                  Public this template
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          )}
          <Button
            className="mt-4 mb-2 mx-auto d-flex justify-content-center align-items-center gap-2"
            type="submit"
            disabled={loading}
          >
            Submit{' '}
            {loading && (
              <Spinner animation="border" role="status" size="sm"></Spinner>
            )}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default UpdateTemplatesPage;
