import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import SegmentForm from 'components/customer-segments/segment';
import Divider from 'components/common/Divider';
import dayjs from 'dayjs';

const AddCustomerSegmentModal = ({
  fields,
  showAddCustomerSegmentModal,
  setShowAddCustomerSegmentModal,
  fetchCustomerSegments
}) => {
  const {
    reset,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm();

  const [loading, setLoading] = useState(false);

  const initNewCustomerSegments = {
    segmentName: '',
    criteria: []
  };

  const [newCustomerSegments, setNewCustomerSegments] = useState(
    initNewCustomerSegments
  );
  const [criteriaError, setCriteriaError] = useState('');
  const [contacts, setContacts] = useState([]);

  const fetchContacts = async () => {
    setLoading(true);

    try {
      const response = await axiosClient.get(
        `/contacts?criteria=${JSON.stringify(newCustomerSegments.criteria)}`
      );
      setLoading(false);

      setContacts(response.data.contacts);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get contacts');
      }
      setLoading(false);
    }
  };

  const handleAddCustomerSegments = async () => {
    if (newCustomerSegments.criteria.length === 0) {
      setCriteriaError('Please add at least one criteria');
      return;
    } else {
      setCriteriaError('');
    }
    setLoading(true);
    try {
      await axiosClient.post('/customer-segments', newCustomerSegments);
      fetchCustomerSegments();
      toast.success('Customer segments added successfully');
      setLoading(false);
      setShowAddCustomerSegmentModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (newCustomerSegments.criteria && newCustomerSegments.criteria.length > 0)
      fetchContacts();
    setCriteriaError('');
  }, [newCustomerSegments.criteria]);

  return (
    <>
      <Modal
        show={showAddCustomerSegmentModal}
        onHide={() => {
          setShowAddCustomerSegmentModal(false);
          setNewCustomerSegments(initNewCustomerSegments);
          reset();
          setCriteriaError('');
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Customer Segments
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          <Form onSubmit={handleSubmit(handleAddCustomerSegments)}>
            <WizardInput
              label="Segment Name"
              name="segmentName"
              errors={errors}
              formGroupProps={{ className: 'mb-3' }}
              formControlProps={{
                ...register('segmentName', {
                  required: 'Segment name is required'
                }),
                onChange: e => {
                  setNewCustomerSegments({
                    ...newCustomerSegments,
                    segmentName: e.target.value
                  });
                  clearErrors('segmentName');
                }
              }}
            />

            <SegmentForm
              fields={fields}
              criteria={newCustomerSegments.criteria}
              onCriteriaChange={data =>
                setNewCustomerSegments({
                  ...newCustomerSegments,
                  criteria: data
                })
              }
              loading={loading}
              criteriaError={criteriaError}
            />
            <Divider className="pt-2 pb-3" />
            <h4>Contacts meet the criteria</h4>

            <div>
              {loading ? (
                <Row className="justify-content-center mb-3 g-3">
                  <Col lg={4} className="d-flex justify-content-center">
                    <Lottie
                      animationData={infiniteLoop}
                      loop={true}
                      style={{ height: '120px', width: '120px' }}
                    />
                  </Col>
                  <h3 className="text-center">Loading...</h3>
                </Row>
              ) : (
                <>
                  {contacts.length > 0 && (
                    <p>
                      {contacts.length} contact{contacts.length > 1 ? 's' : ''}{' '}
                      found{' '}
                    </p>
                  )}
                  <div className="segment-table" style={{ overflowX: 'auto' }}>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th scope="col">First name</th>
                          <th scope="col">Last name</th>
                          <th scope="col" className="email">
                            Email
                          </th>
                          <th scope="col">Phone number</th>
                          <th scope="col">Source</th>
                          <th scope="col" className="text-center">
                            Text Opt-in
                          </th>
                          <th scope="col" className="text-center">
                            Email Opt-in
                          </th>
                          <th scope="col">Last contacted date</th>
                          <th scope="col">Custom fields</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contacts.length > 0 ? (
                          contacts.map((contact, index) => (
                            <tr key={`contact-${index}`}>
                              <td>{contact.firstName}</td>
                              <td>{contact.lastName}</td>
                              <td>{contact.email}</td>
                              <td>{contact.phoneNumber}</td>
                              <td>{contact.source}</td>
                              <td className="text-center">
                                {contact.textOptIn ? (
                                  <GoCheckCircle className="text-success fs-9" />
                                ) : (
                                  <IoIosCloseCircleOutline className="text-danger fs-8" />
                                )}
                              </td>
                              <td className="text-center">
                                {contact.emailOptIn ? (
                                  <GoCheckCircle className="text-success fs-9 " />
                                ) : (
                                  <IoIosCloseCircleOutline className="text-danger fs-8" />
                                )}
                              </td>
                              <td width="80">
                                {dayjs(contact.lastContactedDate).format(
                                  'MM/DD/YYYY HH:mm'
                                )}
                              </td>
                              <td className="text-capitalize">
                                {contact.customFields &&
                                  Object.keys(contact.customFields).map(
                                    (field, index) => (
                                      <div key={index} className="d-flex gap-2">
                                        <span className="text-capitalize">
                                          {field.replaceAll('_', ' ')}:
                                        </span>

                                        <i>
                                          {contact.customFields[field] &&
                                            contact.customFields[
                                              field
                                            ].toString()}
                                        </i>
                                      </div>
                                    )
                                  )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="12" className="text-center">
                              No contacts found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}

              <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
                <Button disabled={loading} type="submit">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

AddCustomerSegmentModal.propTypes = {
  fields: PropTypes.array,
  showAddCustomerSegmentModal: PropTypes.bool,
  setShowAddCustomerSegmentModal: PropTypes.func,
  fetchCustomerSegments: PropTypes.func
};

export default AddCustomerSegmentModal;
