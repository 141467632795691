import WizardInput from 'components/wizard/WizardInput';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import grapesjs from 'grapesjs';
import newsLetter from 'grapesjs-preset-newsletter-v2';
import 'grapesjs/dist/css/grapes.min.css';
import { categories } from 'data/categories';
import { useAppSelector } from 'redux/store';
import { selectUser } from 'redux/reducer/auth';

grapesjs.plugins.add('gjs-preset-newsletter', newsLetter);

const CreateTemplatesPage = () => {
  const user = useAppSelector(selectUser);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();

  const editorRef = useRef(null);
  const [newTemplate, setNewTemplate] = useState({
    type: 'email',
    name: '',
    content: null,
    category: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    if (editorRef.current) {
      setIsLoading(true);
      const html = editorRef.current.getHtml();
      const css = editorRef.current.getCss();
      const components = editorRef.current.getComponents();
      const styles = editorRef.current.getStyle();
      const formData = new FormData();
      formData.append('name', newTemplate.name);
      formData.append('type', newTemplate.type);
      formData.append('category', newTemplate.category);
      const content = {
        html,
        css,
        components,
        styles
      };
      const contentBlob = new Blob([JSON.stringify(content)], {
        type: 'application/json'
      });
      formData.append('content', contentBlob, 'content.json');
      try {
        await axiosClient.post(`/templates/email`, formData);
        toast.success('Template created successfully');
        if (user && user.isAdmin) {
          navigate('/templates/email-template');
        } else {
          navigate('/templates/custom-email-template');
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to create template');
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    editorRef.current = grapesjs.init({
      container: '#gjs1',
      plugins: ['gjs-preset-newsletter'],
      pluginsOpts: {
        'gjs-preset-newsletter': {
          inlineCss: true
        }
      }
    });

    editorRef.current.on('load', () => {
      editorRef.current.runCommand('core:canvas-clear');
      console.log('Editor initialized and cleared');
    });
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="col-12 d-flex flex-wrap gap-2 justify-content-between border-bottom">
          <Card.Title as="h5" id="contained-modal-title-vcenter">
            Create email template
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex flex-column"
          >
            <WizardInput
              label="Template name"
              name="name"
              errors={errors}
              formGroupProps={{ className: 'col-6 mb-3' }}
              formControlProps={{
                ...register('name', {
                  required: 'Template name is required'
                }),
                onChange: e => {
                  setNewTemplate({
                    ...newTemplate,
                    name: e.target.value
                  });
                },
                value: newTemplate.name
              }}
            />
            <Form.Group className="col-6 mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Select
                type="select"
                value={newTemplate.category}
                {...register('category', { required: 'Category is required' })}
                onChange={e => {
                  setNewTemplate({
                    ...newTemplate,
                    category: e.target.value
                  });
                }}
                isInvalid={!!errors.category}
                name="category"
              >
                <option value="">Select category</option>
                {categories.map(option => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
              {errors.category && (
                <div className="invalid-feedback d-block">
                  {errors.category.message}
                </div>
              )}
            </Form.Group>
            <Form.Label>Content</Form.Label>
            <div
              id="gjs1"
              style={{ height: '100vh', border: '1px solid #ddd' }}
            ></div>
            <Button
              className="mt-4 mb-2 mx-auto d-flex justify-content-center align-items-center gap-2"
              type="submit"
              disabled={isLoading}
            >
              Submit{' '}
              {isLoading && (
                <Spinner animation="border" role="status" size="sm"></Spinner>
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateTemplatesPage;
