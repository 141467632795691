import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';

import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification from 'components/notification/Notification';
import {
  getNotification,
  selectNotifications
} from 'redux/reducer/notification';
import { useAppDispatch, useAppSelector } from 'redux/store';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';

const NotificationDropdown = () => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectNotifications);

  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(true);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const markAllAsRead = async e => {
    e.preventDefault();
    try {
      const unreadNotificationIds = notification
        .filter(item => !item.markedAsRead)
        .map(item => item.id)
        .join(',');
      if (!unreadNotificationIds) return;
      await axiosClient.put(`/notifications/${unreadNotificationIds}`);
      dispatch(getNotification());
    } catch (error) {
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to mark as read');
      }
    }
    handleToggle();
  };

  useEffect(() => {
    if (notification && notification.length) {
      setIsAllRead(notification.every(item => item.markedAsRead));
    }
  }, notification);

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-5" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAllAsRead}
              >
                Mark all as read
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs-10 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            {notification && notification.length > 0 ? (
              notification.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item className="text-center my-4">
                No notification found
              </ListGroup.Item>
            )}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="#!">
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
