import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useForm } from 'react-hook-form';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Divider from 'components/common/Divider';
import CustomFieldForm from './CustomFieldForm';

const UpdateContactModal = ({
  showUpdateContactModal,
  setShowUpdateContactModal,
  fetchContacts,
  customFields,
  updatingContact
}) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [contact, setContact] = useState(updatingContact);

  const handleUpdateContact = () => {
    handleSubmit(async () => {
      setIsLoading(true);

      try {
        await axiosClient.put(`/contacts/${updatingContact.id}`, contact);
        fetchContacts();
        toast.success('Contact updated successfully');
        setShowUpdateContactModal(false);

        setIsLoading(false);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to update contact');
        }
        setIsLoading(false);
      }
    })();
  };

  useEffect(() => {
    reset();
    setContact(updatingContact);
  }, [updatingContact, showUpdateContactModal]);

  return (
    <>
      <Modal
        show={showUpdateContactModal}
        onHide={() => {
          setShowUpdateContactModal(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Contact
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoading ? (
            <Row className="justify-content-center mb-3 g-3">
              <Col lg={4} className="d-flex justify-content-center">
                <Lottie
                  animationData={infiniteLoop}
                  loop={true}
                  style={{ height: '120px', width: '120px' }}
                />
              </Col>
              <h3 className="text-center">Loading...</h3>
            </Row>
          ) : (
            <>
              <Form onSubmit={handleUpdateContact}>
                <Row>
                  <WizardInput
                    label="First name"
                    name="firstName"
                    errors={errors}
                    formGroupProps={{ className: 'col-6 mb-3' }}
                    formControlProps={{
                      ...register('firstName', {
                        required: 'First name is required'
                      }),
                      onChange: e => {
                        setContact({
                          ...contact,
                          firstName: e.target.value
                        });
                      },
                      value: updatingContact.firstName
                    }}
                  />
                  <WizardInput
                    label="Last name"
                    name="lastName"
                    errors={errors}
                    formGroupProps={{ className: 'col-6 mb-3' }}
                    formControlProps={{
                      ...register('lastName', {
                        required: 'Last name is required'
                      }),
                      onChange: e => {
                        setContact({
                          ...contact,
                          lastName: e.target.value
                        });
                      },
                      value: updatingContact.lastName
                    }}
                  />
                </Row>

                <WizardInput
                  type="email"
                  errors={errors}
                  label="Email*"
                  name="email"
                  formGroupProps={{ className: 'mb-3' }}
                  formControlProps={{
                    ...register('email', {
                      required: 'Email field is required',
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: 'Email must be valid'
                      }
                    }),
                    onChange: e => {
                      setContact({
                        ...contact,
                        email: e.target.value
                      });
                    },
                    value: contact.email
                  }}
                />
                <WizardInput
                  type="text"
                  label="Phone number"
                  name="phoneNumber"
                  errors={errors}
                  formGroupProps={{
                    className: 'mb-3'
                  }}
                  formControlProps={{
                    className: 'input-spin-none',
                    ...register('phoneNumber', {
                      required: 'Phone number is required'
                    }),
                    onChange: e => {
                      setContact({
                        ...contact,
                        phoneNumber: e.target.value
                      });
                    },
                    value: contact.phoneNumber
                  }}
                />

                <OverlayTrigger
                  placement="top"
                  overlay={
                    contact.optOutEvents.find(
                      item => item.communicationType === 'Text'
                    ) ? (
                      <Tooltip id="text-opt-out-tooltip">
                        This option is disabled because the contact has opted
                        out of text communications.
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div style={{ width: 'fit-content' }}>
                    <Form.Check type="checkbox" id="textOptIn" className="mb-0">
                      <Form.Check.Input
                        type="checkbox"
                        name="textOptIn"
                        checked={contact.textOptIn}
                        onChange={e =>
                          setContact({
                            ...contact,
                            textOptIn: e.target.checked
                          })
                        }
                        disabled={contact.optOutEvents.find(
                          item => item.communicationType === 'Text'
                        )}
                      />
                      <Form.Check.Label className="mb-0 text-700">
                        Text Opt-in
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    contact.optOutEvents.find(
                      item => item.communicationType === 'Email'
                    ) ? (
                      <Tooltip id="email-opt-out-tooltip">
                        This option is disabled because the contact has opted
                        out of email communications, email has bounced or email
                        reported as spam.
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div style={{ width: 'fit-content' }}>
                    <Form.Check
                      type="checkbox"
                      id="emailOptIn"
                      className="mb-0"
                    >
                      <Form.Check.Input
                        type="checkbox"
                        name="emailOptIn"
                        checked={contact.emailOptIn}
                        onChange={e =>
                          setContact({
                            ...contact,
                            emailOptIn: e.target.checked
                          })
                        }
                        disabled={contact.optOutEvents.find(
                          item => item.communicationType === 'Email'
                        )}
                      />
                      <Form.Check.Label className="mb-0 text-700">
                        Email Opt-in
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </OverlayTrigger>
                <Divider></Divider>
                <h5 className="my-3">Custom field</h5>
                <CustomFieldForm
                  customFields={customFields}
                  contact={contact}
                  setContact={setContact}
                />
              </Form>
            </>
          )}
        </Modal.Body>
        <Divider />
        <div className="col-12 d-flex flex-column gap-3 justify-content-center align-items-center px-4 mb-3">
          <Button
            disabled={isLoading}
            onClick={() => handleUpdateContact(false)}
            style={{ width: 'fit-content' }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

UpdateContactModal.propTypes = {
  showUpdateContactModal: PropTypes.bool,
  setShowUpdateContactModal: PropTypes.func,
  fetchContacts: PropTypes.func,
  customFields: PropTypes.array,
  updatingContact: PropTypes.object
};

export default UpdateContactModal;
