import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useForm } from 'react-hook-form';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { FaFileAlt } from 'react-icons/fa';
import dataTypes from 'data/dataTypes';
import Divider from 'components/common/Divider';

const AddCustomFieldModal = ({
  showAddCustomFieldModal,
  setShowAddCustomFieldModal,
  fetchCustomFields
}) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const initNewCustomFields = {
    fieldName: '',
    fieldType: ''
  };

  const [loading, setLoading] = useState(false);
  const [showUploadCustomFields, setShowUploadCustomFields] = useState(false);
  const [file, setFile] = useState(null);

  const [newCustomFields, setNewCustomFields] = useState(initNewCustomFields);

  const addSingleCustomFields = async () => {
    setLoading(true);
    try {
      await axiosClient.post('/custom-fields', newCustomFields);
      fetchCustomFields();
      toast.success('Custom fields added serrorsuccessfully');
      setShowAddCustomFieldModal(false);
      setLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to add contact');
      }
      setLoading(false);
    }
  };

  const addMultipleCustomFields = async () => {
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);

    try {
      const response = await axiosClient.post(
        '/custom-fields/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      if (response.data.errors && response.data.errors.length > 0) {
        response.data.errors.map(error => {
          toast.error(error);
        });
      } else {
        toast.success('Custom fields added successfully');
      }
      fetchCustomFields();
      setShowAddCustomFieldModal(false);
      setLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to upload contact');
      }
      setLoading(false);
    }
  };

  const handleAddCustomFields = () => {
    handleSubmit(async data => {
      if (showUploadCustomFields) {
        addMultipleCustomFields();
      } else {
        addSingleCustomFields(data);
      }
    })();
  };

  useEffect(() => {
    reset();
    setNewCustomFields(initNewCustomFields);
    setFile(null);
  }, [showAddCustomFieldModal, showUploadCustomFields]);

  return (
    <Modal
      show={showAddCustomFieldModal}
      onHide={() => setShowAddCustomFieldModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Custom Fields
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <>
            {showUploadCustomFields ? (
              <div>
                <h5>Add multiple custom fields</h5>
                <div
                  className="upload-box"
                  onClick={() =>
                    document.getElementById('csvFileInput').click()
                  }
                >
                  <FaFileAlt className="fs-6 my-2" />
                  <p className="mb-0">{file?.name || 'Click to upload CSV'}</p>
                </div>
                <Form.Group controlId="formFile" className="d-none">
                  <Form.Control
                    type="file"
                    accept=".csv"
                    id="csvFileInput"
                    onChange={e => setFile(e.target.files[0])}
                  />
                </Form.Group>
              </div>
            ) : (
              <Form onSubmit={handleAddCustomFields}>
                <h5>Add a new custom field</h5>
                <WizardInput
                  label="Field name"
                  name="fieldName"
                  errors={errors}
                  formGroupProps={{ className: 'mb-3' }}
                  formControlProps={{
                    ...register('fieldName', {
                      required: 'Field name is required',
                      validate: {
                        noSpaces: value =>
                          !/\s/.test(value) ||
                          'Field name should not contain spaces',
                        correctFormat: value =>
                          /^[a-zA-Z0-9_]+$/.test(value) ||
                          'Field name should only contain alphanumeric characters and underscores'
                      }
                    }),
                    onChange: e => {
                      setNewCustomFields({
                        ...newCustomFields,
                        fieldName: e.target.value
                      });
                    }
                  }}
                />

                <Form.Group>
                  <Form.Label>Field Type</Form.Label>

                  {Object.values(dataTypes).map(label => (
                    <Form.Check
                      key={label}
                      type="radio"
                      label={label}
                      checked={
                        newCustomFields.fieldType === label.toLowerCase()
                      }
                      name="fieldType"
                      {...register('fieldType')}
                      onChange={() =>
                        setNewCustomFields({
                          ...newCustomFields,
                          fieldType: label.toLowerCase()
                        })
                      }
                    />
                  ))}
                </Form.Group>
              </Form>
            )}

            <Divider>
              <p className="mb-0">Or</p>
            </Divider>
            <p
              className="upload-text text-primary "
              onClick={() => setShowUploadCustomFields(!showUploadCustomFields)}
            >
              {!showUploadCustomFields
                ? 'Add multiple customFields'
                : 'Add single custom field'}
            </p>
          </>
        )}
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button disabled={loading} onClick={() => handleAddCustomFields(false)}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

AddCustomFieldModal.propTypes = {
  showAddCustomFieldModal: PropTypes.bool,
  setShowAddCustomFieldModal: PropTypes.func,
  fetchCustomFields: PropTypes.func
};

export default AddCustomFieldModal;
