import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import SimpleLayout from 'layouts/SimpleLayout';
import SimpleLogin from 'components/authentication/simple/SimpleLogin';
import MainLayout from 'layouts/MainLayout';
import WizardAuth from 'components/authentication/wizard/WizardAuth';
import MainAccountDashboard from 'pages/dashboard';
import UsersPage from 'pages/users';
import UserAccessRequestPage from 'pages/user-access-request';
import SendersPage from 'pages/senders';
import SenderDetailPage from 'pages/senders/[id]';
import ProtectedRoute from './ProtectedRoute';
import ContactsPage from 'pages/contacts';
import CustomFieldsPage from 'pages/custom-fields';
import CustomerSegmentsPage from 'pages/customer-segments';
import CreateTemplatesPage from 'pages/templates/create';
import UpdateTemplatesPage from 'pages/templates/[id]';
import CampaignsPage from 'pages/campaigns';
import GroupsPage from 'pages/groups';
import GroupDetailPage from 'pages/groups/[id]';
import Profile from 'pages/profile';
import TextTemplatesPage from 'pages/templates/text-template';
import ContactFormPage from 'pages/contact-form';
import AdminRoute from './AdminRoute';
import CopyTemplatesPage from 'pages/templates/[id]/copy';
import EmailTemplatesPage from 'pages/templates/email-template';
import CustomEmailTemplatesPage from 'pages/templates/custom-email-template';
import UserEmailTemplatesPage from 'pages/templates/user-email-template';
import VerificationEmailPage from 'pages/verification-email';
import OptOutPage from 'pages/contacts/opt-out';
import OrganizationsPage from 'pages/organizations';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="landing" element={<Landing />} />
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      <Route element={<SimpleLayout />}>
        <Route path="/login" element={<SimpleLogin />} />
      </Route>
      <Route path="/register" element={<WizardAuth />} />
      <Route path="/verification-email" element={<VerificationEmailPage />} />
      <Route path="/contacts/opt-out" element={<OptOutPage />} />
      <Route element={<MainLayout />}>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<MainAccountDashboard />} />
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/organizations" element={<OrganizationsPage />} />
          <Route path="/senders" element={<SendersPage />} />
          <Route path="/senders/:id" element={<SenderDetailPage />} />

          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/custom-fields" element={<CustomFieldsPage />} />
          <Route path="/customer-segments" element={<CustomerSegmentsPage />} />

          <Route
            path="/templates/email-template"
            element={<EmailTemplatesPage />}
          />
          <Route
            path="/templates/custom-email-template"
            element={<CustomEmailTemplatesPage />}
          />
          <Route
            path="/templates/email-template/create"
            element={<CreateTemplatesPage />}
          />
          <Route
            path="/templates/email-template/:id"
            element={<UpdateTemplatesPage />}
          />
          <Route
            path="/templates/email-template/:id/copy"
            element={<CopyTemplatesPage />}
          />
          <Route
            path="/templates/text-template"
            element={<TextTemplatesPage />}
          />
          <Route path="/campaigns" element={<CampaignsPage />} />
          <Route path="/groups" element={<GroupsPage />} />
          <Route path="/groups/:id" element={<GroupDetailPage />} />
          <Route element={<AdminRoute />}>
            <Route path="/users" element={<UsersPage />} />
            <Route
              path="/user-access-request"
              element={<UserAccessRequestPage />}
            />
            <Route
              path="/templates/user-email-template"
              element={<UserEmailTemplatesPage />}
            />
          </Route>
        </Route>
      </Route>
      <Route path="/contact-form" element={<ContactFormPage />} />
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default AppRoutes;
