import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const RemoveContactsFromGroup = ({
  group,
  showRemoveContactModal,
  setShowRemoveContactsFromGroup,
  removingContactIds,
  setRemovingContactIds,
  fetchGroupDetail
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveContacts = async ids => {
    setIsLoading(true);
    try {
      await axiosClient.delete(
        `/groups/${group.id}/remove-contacts?contactIds=${ids.join(',')}`
      );
      fetchGroupDetail();
      toast.success('Contacts removed successfully from group');
      setIsLoading(false);
      setShowRemoveContactsFromGroup(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to remove contact from group');
      }
    }
  };

  return (
    <Modal
      show={showRemoveContactModal}
      onHide={() => {
        setShowRemoveContactsFromGroup(false);
        setRemovingContactIds([]);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Remove Contact{removingContactIds.length > 1 ? 's' : ''} From Group{' '}
          <i>{group?.name}</i>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <div className="d-flex gap-2">
            <span>
              Are you sure you want to remove{' '}
              <b>
                {removingContactIds.length} contact
                {removingContactIds.length > 1 ? 's' : ''}
              </b>{' '}
              from group <i>{group?.name}</i>?
            </span>
          </div>
        )}
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button
          variant="secondary"
          onClick={() => {
            setShowRemoveContactsFromGroup(false);
            setRemovingContactIds([]);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={isLoading}
          onClick={() => {
            handleRemoveContacts(removingContactIds);
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

RemoveContactsFromGroup.propTypes = {
  group: PropTypes.object.isRequired,
  showRemoveContactModal: PropTypes.bool.isRequired,
  setShowRemoveContactsFromGroup: PropTypes.func.isRequired,
  removingContactIds: PropTypes.array.isRequired,
  setRemovingContactIds: PropTypes.func.isRequired,
  fetchGroupDetail: PropTypes.func.isRequired
};

export default RemoveContactsFromGroup;
