import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useForm } from 'react-hook-form';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { IoCloseCircle } from 'react-icons/io5';
import { FaFileAlt } from 'react-icons/fa';
import dataTypes from 'data/dataTypes';
import Divider from 'components/common/Divider';
import CustomFieldForm from './CustomFieldForm';

const AddContactModal = ({
  showAddContactModal,
  setShowAddContactModal,
  fetchContacts,
  customFields
}) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const initNewContact = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    textOptIn: false,
    emailOptIn: false,
    customFields: [
      {
        fieldId: '',
        fieldType: '',
        value: ''
      }
    ]
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showUploadContacts, setShowUploadContacts] = useState(false);

  const [showAddCustomField, setShowAddCustomField] = useState(false);
  const [newFields, setNewFields] = useState([]);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');

  const [newContact, setNewContact] = useState(initNewContact);
  const [newContacts, setNewContacts] = useState([]);

  const addSingleContact = async () => {
    setIsLoading(true);

    try {
      await axiosClient.post('/contacts', newContact);
      fetchContacts();
      toast.success('Contact added successfully');
      setShowAddContactModal(false);
      setFile(null);

      setIsLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to add contact');
      }
      setIsLoading(false);
    }
  };

  const addMultipleContacts = async ({ checkNewFields }) => {
    if (!file) {
      setFileError('Please upload a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    setIsLoading(true);
    const query = checkNewFields ? '?checkNewFields=true' : '';

    try {
      const response = await axiosClient.post(
        `/contacts/upload${query}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      fetchContacts();
      setShowAddContactModal(false);
      setShowAddCustomField(false);
      setFile(null);
      setIsLoading(false);
      toast.success(response.data.message);
      if (
        response.data &&
        response.data.duplicateContacts &&
        response.data.duplicateContacts.length > 0
      ) {
        toast.warning(
          'Some contacts were not added because they already exist.\n They are ' +
            response.data.duplicateContacts
              .map(item => `${item.first_name}  ${item.last_name}`)
              .join(', ')
        );
      }
      if (
        response.data &&
        response.data.inValidContacts &&
        response.data.inValidContacts.length > 0
      ) {
        toast.warning(
          'Some contacts were not added because they are missed mandatory fields (first name, last name, email, phone number). \n They are ' +
            response.data.inValidContacts
              .map(item => `${item.first_name}  ${item.last_name}`)
              .join(', ')
        );
      }

      if (
        response.data &&
        response.data.inCorrectContacts &&
        response.data.inCorrectContacts.length > 0
      ) {
        toast.warning(
          'Some contacts were not added because they have incorrect data. \n They are ' +
            response.data.inCorrectContacts
              .map(item => `${item.first_name}  ${item.last_name}`)
              .join(', ')
        );
      }
    } catch (error) {
      if (error.response.data.newFields) {
        setNewFields(
          error.response.data.newFields.map(field => ({
            fieldName: field,
            fieldType: ''
          }))
        );
        setNewContacts(error.response.data.contacts);
        setShowAddCustomField(true);
        setShowAddContactModal(false);
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to upload contacts');
      }
      setIsLoading(false);
    }
  };

  const handleAddContact = () => {
    handleSubmit(async data => {
      if (showUploadContacts) {
        addMultipleContacts({ checkNewFields: true });
      } else {
        addSingleContact(data);
      }
    })();
  };

  const handleUploadContactsWithNewFields = async _newFields => {
    setIsLoading(true);
    const data = {
      newFields: _newFields,
      contacts: newContacts
    };

    try {
      const response = await axiosClient.post(
        `/contacts/upload-with-new-field`,
        data
      );
      fetchContacts();
      setShowAddContactModal(false);
      setShowAddCustomField(false);
      setFile(null);
      setIsLoading(false);
      toast.success('Contacts added successfully');
      if (
        response.data &&
        response.data.duplicateContacts &&
        response.data.duplicateContacts.length > 0
      ) {
        toast.warning(
          'Some contacts were not added because they already exist'
        );
      }
      if (
        response.data &&
        response.data.inCorrectContacts &&
        response.data.inCorrectContacts.length > 0
      ) {
        toast.warning(
          'Some contacts were not added because they have incorrect data'
        );
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reset();
    setNewContact(initNewContact);
    setFileError('');
    // setFile(null);
  }, [showAddContactModal, showUploadContacts]);

  return (
    <>
      <Modal
        show={showAddContactModal}
        onHide={() => {
          setShowAddContactModal(false);
          setFile(null);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {!showUploadContacts
              ? 'Add single contact'
              : 'Add multiple contacts'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoading ? (
            <Row className="justify-content-center mb-3 g-3">
              <Col lg={4} className="d-flex justify-content-center">
                <Lottie
                  animationData={infiniteLoop}
                  loop={true}
                  style={{ height: '120px', width: '120px' }}
                />
              </Col>
              <h3 className="text-center">Loading...</h3>
            </Row>
          ) : (
            <>
              {showUploadContacts ? (
                <div>
                  <div
                    className="upload-box"
                    onClick={() =>
                      document.getElementById('csvFileInput').click()
                    }
                  >
                    <FaFileAlt className="fs-6 my-2" />
                    <p className="mb-0">
                      {file?.name || 'Click to upload CSV'}
                    </p>
                  </div>
                  <Form.Group controlId="formFile" className="d-none">
                    <Form.Control
                      type="file"
                      accept=".csv"
                      id="csvFileInput"
                      onChange={e => setFile(e.target.files[0])}
                    />
                  </Form.Group>
                  <p className="text-danger fs-10">{fileError}</p>
                </div>
              ) : (
                <Form onSubmit={handleAddContact}>
                  <Row>
                    <WizardInput
                      label="First name"
                      name="firstName"
                      errors={errors}
                      formGroupProps={{ className: 'col-6 mb-3' }}
                      formControlProps={{
                        ...register('firstName', {
                          required: 'First name is required'
                        }),
                        onChange: e => {
                          setNewContact({
                            ...newContact,
                            firstName: e.target.value
                          });
                        }
                      }}
                    />
                    <WizardInput
                      label="Last name"
                      name="lastName"
                      errors={errors}
                      formGroupProps={{ className: 'col-6 mb-3' }}
                      formControlProps={{
                        ...register('lastName', {
                          required: 'Last name is required'
                        }),
                        onChange: e => {
                          setNewContact({
                            ...newContact,
                            lastName: e.target.value
                          });
                        }
                      }}
                    />
                  </Row>

                  <WizardInput
                    type="email"
                    errors={errors}
                    label="Email*"
                    name="email"
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('email', {
                        required: 'Email field is required',
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: 'Email must be valid'
                        }
                      }),
                      onChange: e => {
                        setNewContact({
                          ...newContact,
                          email: e.target.value
                        });
                      }
                    }}
                  />
                  <WizardInput
                    type="text"
                    label="Phone number"
                    name="phoneNumber"
                    errors={errors}
                    formGroupProps={{
                      className: 'mb-3'
                    }}
                    formControlProps={{
                      className: 'input-spin-none',
                      ...register('phoneNumber', {
                        required: 'Phone number is required'
                      }),
                      onChange: e => {
                        setNewContact({
                          ...newContact,
                          phoneNumber: e.target.value
                        });
                      }
                    }}
                  />

                  <Form.Check type="checkbox" id="textOptIn" className="mb-0">
                    <Form.Check.Input
                      type="checkbox"
                      name="textOptIn"
                      checked={newContact.textOptIn}
                      onChange={e =>
                        setNewContact({
                          ...newContact,
                          textOptIn: e.target.checked
                        })
                      }
                    />
                    <Form.Check.Label className="mb-0 text-700">
                      Text Opt-in
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check type="checkbox" id="emailOptIn" className="mb-0">
                    <Form.Check.Input
                      type="checkbox"
                      name="emailOptIn"
                      checked={newContact.emailOptIn}
                      onChange={e =>
                        setNewContact({
                          ...newContact,
                          emailOptIn: e.target.checked
                        })
                      }
                    />
                    <Form.Check.Label className="mb-0 text-700">
                      Email Opt-in
                    </Form.Check.Label>
                  </Form.Check>
                  <Divider></Divider>
                  <h5 className="my-3">Custom field</h5>
                  <CustomFieldForm
                    customFields={customFields}
                    contact={newContact}
                    setContact={setNewContact}
                  />
                </Form>
              )}
            </>
          )}
        </Modal.Body>
        <Divider />
        <div className="col-12 d-flex flex-column gap-3 justify-content-center align-items-center px-4 mb-3">
          <Button
            disabled={isLoading}
            onClick={() => handleAddContact(false)}
            style={{ width: 'fit-content' }}
          >
            Submit
          </Button>
          <p
            className="upload-text text-primary "
            onClick={() => setShowUploadContacts(!showUploadContacts)}
          >
            {!showUploadContacts
              ? 'Add multiple contacts'
              : 'Add single contact'}
          </p>
        </div>
      </Modal>
      <Modal
        show={showAddCustomField}
        onHide={() => setShowAddCustomField(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Unknow fields
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoading ? (
            <Row className="justify-content-center mb-3 g-3">
              <Col lg={4} className="d-flex justify-content-center">
                <Lottie
                  animationData={infiniteLoop}
                  loop={true}
                  style={{ height: '120px', width: '120px' }}
                />
              </Col>
              <h3 className="text-center">Loading...</h3>
            </Row>
          ) : (
            <>
              <Form>
                {newFields.length > 0 && (
                  <p>
                    The following fields are not defined. You can either add
                    them as custom fields or ignore them:
                  </p>
                )}
                {newFields.map((field, index) => (
                  <Row key={`new-field-${index}`}>
                    <Col md={6}>
                      <WizardInput
                        label="Field name"
                        name={`fieldName-${index}`}
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                          value: field.fieldName,
                          onChange: e => {
                            const updatedFields = [...newFields];
                            updatedFields[index].fieldName = e.target.value;
                            setNewFields(updatedFields);
                          },
                          disabled: true
                        }}
                      />
                    </Col>
                    <Col md={5}>
                      <WizardInput
                        type="select"
                        name={`fieldType-${index}`}
                        label="Type"
                        errors={errors}
                        options={Object.values(dataTypes)}
                        formControlProps={{
                          ...register(`fieldType-${index}`, {
                            required: 'Select a type'
                          }),
                          value: field.fieldType,
                          onChange: e => {
                            const updatedFields = [...newFields];
                            updatedFields[index].fieldType = e.target.value;
                            setNewFields(updatedFields);
                          }
                        }}
                      />
                    </Col>
                    <Col
                      md={1}
                      className="ps-0"
                      onClick={() => {
                        const updatedFields = [...newFields];
                        updatedFields.splice(index, 1);
                        setNewFields(updatedFields);
                      }}
                    >
                      <IoCloseCircle className="icon-delete" />
                    </Col>
                  </Row>
                ))}
              </Form>
            </>
          )}
        </Modal.Body>
        <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
          <Button
            disabled={isLoading}
            onClick={() => handleUploadContactsWithNewFields([])}
            variant="secondary "
          >
            Ignore
          </Button>
          <Button
            disabled={isLoading}
            onClick={() =>
              handleSubmit(async () => {
                handleUploadContactsWithNewFields(newFields);
              })()
            }
          >
            Add new fields
          </Button>
        </div>
      </Modal>
    </>
  );
};

AddContactModal.propTypes = {
  showAddContactModal: PropTypes.bool,
  setShowAddContactModal: PropTypes.func,
  fetchContacts: PropTypes.func,
  customFields: PropTypes.array
};

export default AddContactModal;
