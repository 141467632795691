import axios from 'axios';
import queryString from 'query-string';

const config = {
  baseURL: `${process.env.REACT_APP_API_URL}/api`,

  paramsSerializer: params => queryString.stringify(params)
};

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = token;
    }

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else if (config.headers['Content-Type'] === 'application/json') {
      config.data = JSON.stringify(config.data);
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    throw error;
  }
);
export default axiosClient;
