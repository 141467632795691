import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from 'components/wizard/WizardInput';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { selectOrganization, selectUser } from 'redux/reducer/auth';
import { useAppSelector } from 'redux/store';
import {
  ExecutionType,
  campaignObjective,
  CampaignType,
  campaignTypes
} from 'data/common';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import dayjs from 'dayjs';

const CampaignForm = ({
  register,
  errors,
  control,
  campaign,
  setCampaign,
  setValue,
  isViewingCampaign
}) => {
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const [communicationEnabledTypes, setCommunicationEnabledTypes] = useState(
    []
  );
  const updateExecutions = (campaignType, communicationTypes) => {
    const _campaign = {
      ...campaign,
      communicationTypes: communicationTypes,
      campaignType: campaignType
    };

    if (campaignType !== campaign.campaignType && !!campaignType) {
      _campaign.numberOfExecutions = null;
      _campaign.recurrencePattern = '';
      if (campaignType !== CampaignType.DRIP) {
        const execution = {
          index: 0,
          segmentIds: [],
          groupIds: [],
          contactIds: [],
          scheduledStartDate: dayjs(new Date()).format('MM/DD/YYYY HH:mm'),
          campaignCommunications: [
            {
              templateId: '',
              senderId: '',
              communicationType: 'Email',
              title: '',
              enabled: !!communicationTypes.find(item => item === 'Email')
            },
            {
              templateId: '',
              senderId: '',
              communicationType: 'Text',
              title: '',
              enabled: !!communicationTypes.find(item => item === 'Text')
            }
          ]
        };
        if (campaignType === CampaignType.RECURRING) {
          execution.type = ExecutionType.MANUAL;
          execution.endDate = dayjs(new Date()).format('MM/DD/YYYY HH:mm');
          execution.frequency = '';
        }
        _campaign.campaignExecutions = [execution];
      } else {
        _campaign.campaignExecutions = [];
      }
    }
    setCampaign(_campaign);
  };

  useEffect(() => {
    if (user) {
      const enabledTypes = [];
      if (organization.textSendingEnabled) enabledTypes.push('Text');
      if (organization.emailSendingEnabled) enabledTypes.push('Email');
      setCommunicationEnabledTypes(enabledTypes);
    }
  }, [user]);
  return (
    <>
      <WizardInput
        label="Name"
        name="name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('name', {
            required: 'Campaign name is required'
          }),
          value: campaign.name,
          onChange: e => {
            setCampaign({ ...campaign, name: e.target.value });
          },
          disabled: isViewingCampaign
        }}
      />
      <WizardInput
        type="textarea"
        label="Description"
        name="description"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('description', {
            required: 'Description is required'
          }),
          value: campaign.description,
          onChange: e => {
            setCampaign({ ...campaign, description: e.target.value });
          },
          disabled: isViewingCampaign
        }}
      />

      <Form.Group className="mb-3">
        <Form.Label>Campaign type</Form.Label>
        <Controller
          name="campaignType"
          control={control}
          rules={{ required: 'Please select campaign type' }}
          defaultValue={campaign.campaignType}
          render={({ field }) => (
            <Form.Select
              {...field}
              type="select"
              value={campaign.campaignType}
              onChange={e => {
                updateExecutions(e.target.value, campaign.communicationTypes);
                setValue('campaignType', e.target.value);
              }}
              name="campaignType"
              disabled={isViewingCampaign}
            >
              <option value="">Select campaign type</option>
              {campaignTypes.map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          )}
        />
        {errors['campaignType'] && (
          <div className="invalid-feedback d-block">
            {errors['campaignType'].message}
          </div>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Campaign objective</Form.Label>
        <Controller
          name="objective"
          control={control}
          rules={{ required: 'Please select campaign objective' }}
          defaultValue={campaign.objective}
          render={({ field }) => (
            <Form.Select
              {...field}
              type="select"
              value={campaign.objective}
              onChange={e => {
                setCampaign({
                  ...campaign,
                  objective: e.target.value
                });
                setValue('objective', e.target.value);
              }}
              name="objective"
              disabled={isViewingCampaign}
            >
              <option value="">Select campaign objective</option>
              {campaignObjective.map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          )}
        />
        {errors['objective'] && (
          <div className="invalid-feedback d-block">
            {errors['objective'].message}
          </div>
        )}
      </Form.Group>

      <div className="mb-3">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="communicationTypeTooltip">
              Only communication types enabled for your account are selectable
              here
            </Tooltip>
          }
        >
          <Form.Label>Communication type</Form.Label>
        </OverlayTrigger>
        <Controller
          name="communicationTypes"
          control={control}
          rules={{ required: 'Please select communication type' }}
          defaultValue={campaign.communicationTypes.map(item => ({
            value: item,
            label: item
          }))}
          render={({ field }) => (
            <Select
              {...field}
              options={communicationEnabledTypes.map(item => ({
                value: item,
                label: item
              }))}
              placeholder="Select segment channel"
              isMulti
              classNamePrefix="react-select"
              name="communicationTypes"
              value={campaign.communicationTypes.map(item => ({
                value: item,
                label: item
              }))}
              onChange={data => {
                if (campaign.campaignExecutions.length === 0) {
                  updateExecutions(
                    campaign.campaignType,
                    data.map(item => item.value)
                  );
                } else {
                  setCampaign({
                    ...campaign,
                    communicationTypes: data.map(item => item.value),
                    campaignExecutions: campaign.campaignExecutions.map(
                      execution => ({
                        ...execution,
                        campaignCommunications:
                          execution.campaignCommunications.map(item => ({
                            ...item,
                            enabled: !!data.find(
                              type => type.value === item.communicationType
                            )
                          }))
                      })
                    )
                  });
                }

                setValue('communicationTypes', data);
              }}
              isDisabled={isViewingCampaign}
            />
          )}
        />
        {errors['communicationTypes'] && (
          <div className="invalid-feedback d-block">
            {errors['communicationTypes'].message}
          </div>
        )}
      </div>
    </>
  );
};

CampaignForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object,
  setValue: PropTypes.func,
  campaign: PropTypes.object,
  setCampaign: PropTypes.func,
  isViewingCampaign: PropTypes.bool
};

export default CampaignForm;
