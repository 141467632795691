import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useForm } from 'react-hook-form';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Divider from 'components/common/Divider';

const AddSenderModal = ({
  showAddSenderModal,
  setShowAddSenderModal,
  fetchSenders
}) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const initNewSender = {
    username: '',
    email: ''
  };

  const [isLoading, setIsLoading] = useState(false);

  const [newSender, setNewSender] = useState(initNewSender);

  const handleAddSender = () => {
    handleSubmit(async () => {
      setIsLoading(true);

      try {
        await axiosClient.post('/email-account', newSender);
        fetchSenders();
        toast.success('Sender added successfully');
        setShowAddSenderModal(false);

        setIsLoading(false);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to add sender');
        }
        setIsLoading(false);
      }
    })();
  };

  useEffect(() => {
    reset();
    setNewSender(initNewSender);
  }, [showAddSenderModal]);

  return (
    <>
      <Modal
        show={showAddSenderModal}
        onHide={() => {
          setShowAddSenderModal(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Sender
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoading ? (
            <Row className="justify-content-center mb-3 g-3">
              <Col lg={4} className="d-flex justify-content-center">
                <Lottie
                  animationData={infiniteLoop}
                  loop={true}
                  style={{ height: '120px', width: '120px' }}
                />
              </Col>
              <h3 className="text-center">Loading...</h3>
            </Row>
          ) : (
            <>
              <Form onSubmit={handleAddSender}>
                <WizardInput
                  label="Username*"
                  name="username"
                  errors={errors}
                  formGroupProps={{ className: 'mb-3' }}
                  formControlProps={{
                    ...register('username', {
                      required: 'Username is required'
                    }),
                    onChange: e => {
                      setNewSender({
                        ...newSender,
                        username: e.target.value
                      });
                    }
                  }}
                />

                <WizardInput
                  type="email"
                  errors={errors}
                  label="Email*"
                  name="email"
                  formGroupProps={{ className: 'mb-3' }}
                  formControlProps={{
                    ...register('email', {
                      required: 'Email field is required',
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: 'Email must be valid'
                      }
                    }),
                    onChange: e => {
                      setNewSender({
                        ...newSender,
                        email: e.target.value
                      });
                    }
                  }}
                />
              </Form>
            </>
          )}
        </Modal.Body>
        <Divider />
        <div className="col-12 d-flex flex-column gap-3 justify-content-center align-items-center px-4 mb-3">
          <Button
            disabled={isLoading}
            onClick={() => handleAddSender(false)}
            style={{ width: 'fit-content' }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

AddSenderModal.propTypes = {
  showAddSenderModal: PropTypes.bool,
  setShowAddSenderModal: PropTypes.func,
  fetchSenders: PropTypes.func
};

export default AddSenderModal;
