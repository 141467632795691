import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const DeleteTemplateModal = ({
  showDeleteTemplateModal,
  setShowDeleteTemplateModal,
  deletingTemplate,
  setDeletingTemplate,
  fetchTemplates
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteTemplates = async id => {
    setIsLoading(true);
    try {
      await axiosClient.delete(`/templates/${id}`);
      fetchTemplates();
      toast.success('Templates deleted successfully');
      setIsLoading(false);
      setShowDeleteTemplateModal(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to delete template');
      }
    }
  };

  return (
    <Modal
      show={showDeleteTemplateModal}
      onHide={() => {
        setShowDeleteTemplateModal(false);
        setDeletingTemplate([]);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Template
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <div className="d-flex gap-2">
            <span>
              Are you sure you want to delete template{' '}
              <strong>{deletingTemplate.name}</strong> ?
            </span>
          </div>
        )}
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button
          variant="secondary"
          onClick={() => {
            setShowDeleteTemplateModal(false);
            setDeletingTemplate([]);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={isLoading}
          onClick={() => {
            handleDeleteTemplates(deletingTemplate.id);
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

DeleteTemplateModal.propTypes = {
  showDeleteTemplateModal: PropTypes.bool.isRequired,
  setShowDeleteTemplateModal: PropTypes.func.isRequired,
  deletingTemplate: PropTypes.array.isRequired,
  setDeletingTemplate: PropTypes.func.isRequired,
  fetchTemplates: PropTypes.func.isRequired
};

export default DeleteTemplateModal;
