import React from 'react';
import PropTypes from 'prop-types';
import { periodType } from 'data/common';
import Flex from 'components/common/Flex';
import { Card, Form } from 'react-bootstrap';

const SendingStatistics = ({ onPeriodChange }) => {
  return (
    <Card className="h-md-100">
      <Card.Header className="border-bottom">
        <h5 className="mb-2 mt-2">Daily email usage</h5>
        <p className="mb-0 fs-11">
          The following charts show the number of successful send requests, as
          well as the rejection, bounce and complaint rate
        </p>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
          <Form.Select
            size="sm"
            className="me-2"
            onChange={e => {
              onPeriodChange(e.target.value);
            }}
          >
            <option>{periodType.DAY}</option>
            <option>{periodType.WEEK}</option>
            <option>{periodType.MONTH}</option>
          </Form.Select>
        </div>
      </Card.Body>
    </Card>
  );
};

SendingStatistics.propTypes = {
  onPeriodChange: PropTypes.func.isRequired
};

export default SendingStatistics;
