import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteSenderModal = ({
  openModal,
  handleCloseModal,
  data,
  handleSubmitModal,
  isLoading
}) => {
  return (
    <Modal
      show={openModal}
      onHide={handleCloseModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Sender{data.length > 1 ? 's' : ''}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex gap-2">
          <span>
            Are you sure you want to delete {` `}
            <b>
              {data.length} sender
              {data.length > 1 ? 's' : ''}
            </b>
            ?
          </span>
        </div>
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>

        <Button disabled={isLoading} onClick={handleSubmitModal}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </Modal>
  );
};

DeleteSenderModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  handleSubmitModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default DeleteSenderModal;
