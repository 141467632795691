import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Nav, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import AccountForm from './AccountForm';
import PlanForm from './PlanForm';
import Success from './Success';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import IconButton from 'components/common/IconButton';
import WizardModal from './WizardModal';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectError, selectIsLoading } from 'redux/reducer/auth';
import { register as registerUser } from 'redux/reducer/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';

const WizardLayout = ({ variant, validation, progressBar }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectError);
  const isLoading = useAppSelector(selectIsLoading);
  const { isRTL } = useAppContext();
  const { user, setUser, step, setStep } = useAuthWizardContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    clearErrors
  } = useForm();

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'lock',
      label: 'Account'
    },
    {
      icon: 'user',
      label: 'Plan'
    },
    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  const onSubmitData = async data => {
    const newData = {
      ...data,
      businessType:
        data.businessType === 'Other' && data.otherBusinessType
          ? data.otherBusinessType
          : data.businessType
    };
    setUser({ ...user, ...newData });
    if (step === 1) {
      setStep(step + 1);
    } else {
      await dispatch(registerUser(newData));
      toast.success('Account created successfully');
      navigate('/login');
    }
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (isLoading) return;
    if (step !== 3) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(`Failed to login: ${error.message}`, {
        theme: 'colored'
      });
    }
  }, [error]);

  useEffect(() => {
    setStep(1);
  }, []);

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-body-tertiary', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-4 px-md-6 py-4">
          {isLoading ? (
            <Row className="justify-content-center mb-3 g-3">
              <Col lg={4} className="d-flex justify-content-center">
                <Lottie
                  animationData={infiniteLoop}
                  loop={true}
                  style={{ height: '120px', width: '120px' }}
                />
              </Col>
              <h3 className="text-center">Loading...</h3>
            </Row>
          ) : (
            <>
              {step === 1 && (
                <AccountForm
                  register={register}
                  errors={errors}
                  watch={watch}
                />
              )}
              {step === 2 && <PlanForm register={register} errors={errors} />}
              {step === 3 && <Success reset={reset} />}
            </>
          )}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-body-tertiary', {
            'd-none': step === 3,
            ' d-flex': step < 3
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semibold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
            disabled={isLoading}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
            disabled={isLoading}
          >
            {step === 2 ? 'Register' : 'Next'}
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: index < 3 ? step > index : step > 3,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
