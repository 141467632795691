export const businessTypes = [
  'Retail',
  'Healthcare',
  'Hospitality',
  'Real Estate',
  'Education',
  'Financial Services',
  'Technology',
  'Non-Profit Organizations',
  'Manufacturing',
  'Legal Services',
  'Consulting Services',
  'Entertainment',
  'Automotive',
  'Agriculture',
  'Logistics and Transportation',
  'Media and Advertising',
  'Telecommunications',
  'Fashion and Apparel',
  'Food and Beverage',
  'Beauty and Wellness',
  'Other'
];
