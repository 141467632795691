import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { useForm } from 'react-hook-form';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const UpdateCustomFieldModal = ({
  showUpdateCustomFieldModal,
  setShowUpdateCustomFieldModal,
  updatingCustomField,
  fetchCustomFields
}) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [customField, setCustomField] = useState(updatingCustomField);
  const [loading, setLoading] = useState(false);
  const handleUpdateCustomFields = async () => {
    handleSubmit(async () => {
      setLoading(true);
      try {
        await axiosClient.put(`/custom-fields/${customField.id}`, customField);
        fetchCustomFields();
        toast.success('Custom fields updated successfully');
        setShowUpdateCustomFieldModal(false);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    reset();
    setCustomField(updatingCustomField);
  }, [updatingCustomField, showUpdateCustomFieldModal]);

  return (
    <Modal
      show={showUpdateCustomFieldModal}
      onHide={() => setShowUpdateCustomFieldModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Custom Fields
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <>
            <Form onSubmit={handleUpdateCustomFields}>
              <WizardInput
                label="Field name"
                name="fieldName"
                errors={errors}
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                  ...register('fieldName', {
                    required: 'Field name is required',
                    validate: {
                      noSpaces: value =>
                        !/\s/.test(value) ||
                        'Field name should not contain spaces',
                      correctFormat: value =>
                        /^[a-zA-Z0-9_]+$/.test(value) ||
                        'Field name should only contain alphanumeric characters and underscores'
                    },
                    value: updatingCustomField.fieldName
                  }),
                  onChange: e => {
                    setCustomField({
                      ...customField,
                      fieldName: e.target.value
                    });
                  }
                }}
              />

              <Form.Group>
                <Form.Label>Field Type: {customField.fieldType}</Form.Label>
              </Form.Group>
              <i className="fs-10">
                Note: if you want a different type , you should create a new
                field
              </i>
            </Form>
          </>
        )}
      </Modal.Body>
      <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
        <Button
          disabled={loading}
          onClick={() => handleUpdateCustomFields(false)}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

UpdateCustomFieldModal.propTypes = {
  showUpdateCustomFieldModal: PropTypes.bool,
  setShowUpdateCustomFieldModal: PropTypes.func,
  updatingCustomField: PropTypes.object,
  fetchCustomFields: PropTypes.func
};

export default UpdateCustomFieldModal;
