import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Divider from 'components/common/Divider';
import classNames from 'classnames';
import ExecutionForm from './ExecutionForm';
import {
  CampaignType,
  ExecutionType,
  RecurrencePattern,
  scheduleTypes
} from 'data/common';
import WizardInput from 'components/wizard/WizardInput';
import dayjs from 'dayjs';
import DateTimePicker from 'react-datetime-picker';

const ScheduleForm = ({
  emailSenders,
  textSenders,
  templates,
  segments,
  groups,
  contacts,
  isViewingCampaign,
  errors,
  setError,
  control,
  campaign,
  setCampaign,
  setValue,
  getValues,
  clearErrors,
  register,
  unregister,
  setExecutionError
}) => {
  const [showDeleteCurrentExecutionModal, setShowDeleteCurrentExecutionModal] =
    useState(false);

  const addExecutionScheduler = () => {
    const scheduledStartDate = campaign.scheduledStartDate;
    const recurrencePattern = campaign.recurrencePattern;
    const numberOfExecutions = campaign.numberOfExecutions;

    if (numberOfExecutions !== campaign.numberOfExecutions) {
      const values = getValues();
      Object.keys(values).forEach(fieldName => {
        if (
          fieldName.includes('automatic-template') ||
          fieldName.includes('automatic-segmentIds') ||
          fieldName.includes('automatic-groupIds') ||
          fieldName.includes('automatic-contactIds') ||
          fieldName.includes('automatic-scheduledStartDate')
        ) {
          unregister(fieldName);
        }
      });
    }
    const executions = campaign.campaignExecutions.filter(
      execution => execution.type === ExecutionType.MANUAL
    );
    for (let i = 0; i < numberOfExecutions; i++) {
      let time = 0;
      let startDate = dayjs(new Date()).format('MM/DD/YYYY HH:mm');
      if (recurrencePattern) {
        switch (recurrencePattern.toLowerCase()) {
          case RecurrencePattern.HOURLY:
            time = 60 * 60 * 1000; // 1 hour in milliseconds
            startDate = new Date(
              new Date(scheduledStartDate).getTime() + i * time
            );
            break;
          case RecurrencePattern.DAILY:
            time = 24 * 60 * 60 * 1000; // 1 day in milliseconds
            startDate = new Date(
              new Date(scheduledStartDate).getTime() + i * time
            );
            break;
          case RecurrencePattern.WEEKLY:
            time = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds
            startDate = new Date(
              new Date(scheduledStartDate).getTime() + i * time
            );
            break;
          case RecurrencePattern.MONTHLY:
            startDate = new Date(
              new Date(scheduledStartDate).setMonth(
                new Date(scheduledStartDate).getMonth() + i
              )
            );
            break;
          case RecurrencePattern.YEARLY:
            startDate = new Date(
              new Date(scheduledStartDate).setFullYear(
                new Date(scheduledStartDate).getFullYear() + i
              )
            );
            break;
          default:
            throw new Error('Invalid recurrence pattern');
        }
      }
      const newExecution = {
        index: i,
        type: ExecutionType.AUTOMATIC,
        scheduledStartDate: dayjs(startDate).format('MM/DD/YYYY HH:mm'),
        segmentIds: [],
        groupIds: [],
        contactIds: [],
        campaignCommunications: [
          {
            templateId: '',
            senderId: campaign.emailSender,
            communicationType: 'Email',
            title: '',
            enabled: campaign.communicationTypes.includes('Email')
          },
          {
            templateId: '',
            senderId: campaign.textSender,
            communicationType: 'Text',
            title: '',
            enabled: campaign.communicationTypes.includes('Text')
          }
        ]
      };
      setValue(
        `automatic-scheduledStartDate-${i}`,
        dayjs(startDate).format('MM/DD/YYYY HH:mm')
      );

      executions.push(newExecution);
    }
    setCampaign({
      ...campaign,
      campaignExecutions: executions
    });
  };

  const removeExecution = (execution, index) => {
    const executions = [
      ...campaign.campaignExecutions.filter(item => item !== execution)
    ];
    setCampaign({
      ...campaign,
      campaignExecutions: executions
    });
    const type = execution.type || ExecutionType.AUTOMATIC;
    execution.campaignCommunications.forEach(communication => {
      unregister(
        `${type}-template-${communication.communicationType}-${index}`
      );
    });
    unregister(`${type}-segmentIds-${index}`);
    unregister(`${type}-groupIds-${index}`);
    unregister(`${type}-contactIds-${index}`);
    clearErrors(`${type}-contact-${index}`);
  };

  const addManuallyExecution = () => {
    if (
      campaign.campaignType === CampaignType.RECURRING &&
      campaign.campaignExecutions.length >= 5
    ) {
      setExecutionError(
        'With recurring campaign, number of executions should not be more than 5'
      );
      return;
    }
    const executions = campaign.campaignExecutions;
    const manuallyExecutions = executions.filter(
      item => item.type === ExecutionType.MANUAL
    );
    const execution = {
      index:
        manuallyExecutions.length > 0
          ? manuallyExecutions[manuallyExecutions.length - 1].index + 1
          : 0,
      type: ExecutionType.MANUAL,
      scheduledStartDate: dayjs(new Date()).format('MM/DD/YYYY HH:mm'),
      segmentIds: [],
      groupIds: [],
      contactIds: [],
      campaignCommunications: [
        {
          templateId: '',
          senderId: campaign.emailSender,
          communicationType: 'Email',
          title: '',
          enabled: campaign.communicationTypes.includes('Email')
        },
        {
          templateId: '',
          senderId: campaign.textSender,
          communicationType: 'Text',
          title: '',
          enabled: campaign.communicationTypes.includes('Text')
        }
      ]
    };
    if (campaign.campaignType === CampaignType.RECURRING) {
      execution.endDate = dayjs(new Date()).format('MM/DD/YYYY HH:mm');
      execution.frequency = '';
    }
    executions.push(execution);
    setCampaign({
      ...campaign,
      campaignExecutions: executions
    });
  };

  useEffect(() => {
    if (campaign.campaignExecutions.length === 0) addExecutionScheduler();
  }, []);
  return (
    <>
      <Row>
        {campaign.communicationTypes.includes('Email') && (
          <Form.Group
            className={classNames(
              'mb-3',
              campaign.communicationTypes.includes('Text') && 'col-6'
            )}
          >
            <Form.Label>Email sender</Form.Label>
            <Controller
              name="emailSender"
              control={control}
              rules={{ required: 'Please select email sender' }}
              defaultValue={campaign.emailSender}
              render={({ field }) => (
                <Form.Select
                  {...field}
                  type="select"
                  value={campaign.emailSender}
                  onChange={e => {
                    setCampaign({
                      ...campaign,
                      emailSender: e.target.value,
                      campaignExecutions: campaign.campaignExecutions.map(
                        execution => ({
                          ...execution,
                          campaignCommunications:
                            execution.campaignCommunications.map(
                              communication => {
                                if (
                                  communication.communicationType === 'Email'
                                ) {
                                  return {
                                    ...communication,
                                    senderId: e.target.value
                                  };
                                }
                                return communication;
                              }
                            )
                        })
                      )
                    });
                    setValue('emailSender', e.target.value);
                    clearErrors('emailSender');
                  }}
                  name="emailSender"
                  disabled={isViewingCampaign}
                >
                  <option value="">Select sender</option>
                  {emailSenders.map(
                    option =>
                      option.isVerified && (
                        <option value={option.id} key={option.id}>
                          {option.username}
                        </option>
                      )
                  )}
                </Form.Select>
              )}
            />
            {errors['emailSender'] && (
              <div className="invalid-feedback d-block">
                {errors['emailSender'].message}
              </div>
            )}
          </Form.Group>
        )}
        {campaign.communicationTypes.includes('Text') && (
          <Form.Group
            className={classNames(
              'mb-3',
              campaign.communicationTypes.includes('Email') && 'col-6'
            )}
          >
            <Form.Label>Text sender</Form.Label>
            <Controller
              name="textSender"
              control={control}
              rules={{ required: 'Please select text sender' }}
              defaultValue={campaign.textSender}
              render={({ field }) => (
                <Form.Select
                  {...field}
                  type="select"
                  value={campaign.textSender}
                  onChange={e => {
                    setCampaign({
                      ...campaign,
                      textSender: e.target.value,
                      campaignExecutions: campaign.campaignExecutions.map(
                        execution => ({
                          ...execution,
                          campaignCommunications:
                            execution.campaignCommunications.map(
                              communication => {
                                if (
                                  communication.communicationType === 'Text'
                                ) {
                                  return {
                                    ...communication,
                                    senderId: e.target.value
                                  };
                                }
                                return communication;
                              }
                            )
                        })
                      )
                    });
                    setValue('textSender', e.target.value);
                    clearErrors('textSender');
                  }}
                  name="textSender"
                  disabled={isViewingCampaign}
                >
                  <option value="">Select sender</option>
                  {textSenders &&
                    textSenders.length > 0 &&
                    textSenders.map(option => (
                      <option value={option.id} key={option.id}>
                        {`${option.name} (${option.phoneNumber})`}
                      </option>
                    ))}
                </Form.Select>
              )}
            />
            {errors['textSender'] && (
              <div className="invalid-feedback d-block">
                {errors['textSender'].message}
              </div>
            )}
          </Form.Group>
        )}
      </Row>
      {campaign.campaignType === CampaignType.DRIP && (
        <ExecutionSchedulerForm
          campaign={campaign}
          setCampaign={setCampaign}
          setShowDeleteCurrentExecutionModal={
            setShowDeleteCurrentExecutionModal
          }
          addExecutionScheduler={addExecutionScheduler}
          isViewingCampaign={isViewingCampaign}
        />
      )}
      {campaign.campaignType !== CampaignType.RECURRING && (
        <>
          <Divider />
          <h5 className="mb-3">Execution</h5>
          {campaign.campaignExecutions
            .filter(item => item.type !== ExecutionType.MANUAL)
            .map((execution, index) => (
              <div key={index} className="box mb-3">
                {campaign.campaignType !== 'One-off' ? (
                  <div className="d-flex justify-content-between">
                    <p className="font-bold mb-0">Execution {index + 1}</p>
                    {!isViewingCampaign && (
                      <Button
                        variant="danger"
                        onClick={() =>
                          removeExecution(execution, execution.index)
                        }
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                ) : (
                  <p className="font-bold mb-0">Execution </p>
                )}

                <ExecutionForm
                  index={execution.index}
                  execution={execution}
                  onChange={(oldExecution, newExecution) => {
                    setCampaign({
                      ...campaign,
                      campaignExecutions: campaign.campaignExecutions.map(
                        item =>
                          item === oldExecution ? { ...newExecution } : item
                      )
                    });
                  }}
                  campaign={campaign}
                  errors={errors}
                  control={control}
                  setError={setError}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  register={register}
                  templates={templates}
                  segments={segments}
                  groups={groups}
                  contacts={contacts}
                  isViewingCampaign={isViewingCampaign}
                />
              </div>
            ))}
        </>
      )}
      {campaign.campaignType !== CampaignType.ONEOFF && (
        <>
          <Divider />
          <div className="d-flex justify-content-between mb-3">
            <h5>Manual execution</h5>
            {!isViewingCampaign && (
              <Button onClick={addManuallyExecution}>Add manual</Button>
            )}
          </div>
          {campaign.campaignExecutions
            .filter(item => item.type === ExecutionType.MANUAL)
            .map((execution, index) => (
              <div key={index} className="box mb-3">
                {campaign.campaignType !== 'One-off' ? (
                  <div className="d-flex justify-content-between">
                    <p className="font-bold mb-0">
                      Execution{' '}
                      {campaign.campaignExecutions.filter(
                        item => item.type !== ExecutionType.MANUAL
                      ).length +
                        index +
                        1}
                    </p>
                    {!isViewingCampaign && (
                      <Button
                        variant="danger"
                        onClick={() =>
                          removeExecution(execution, execution.index)
                        }
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                ) : (
                  <p className="font-bold mb-0">Execution </p>
                )}

                <ExecutionForm
                  index={execution.index}
                  execution={execution}
                  onChange={(oldExecution, newExecution) => {
                    setCampaign({
                      ...campaign,
                      campaignExecutions: campaign.campaignExecutions.map(
                        item =>
                          item === oldExecution ? { ...newExecution } : item
                      )
                    });
                  }}
                  campaign={campaign}
                  errors={errors}
                  control={control}
                  setError={setError}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  register={register}
                  templates={templates}
                  segments={segments}
                  groups={groups}
                  contacts={contacts}
                  isViewingCampaign={isViewingCampaign}
                />
              </div>
            ))}
          <Divider />
        </>
      )}
      <Modal
        show={showDeleteCurrentExecutionModal}
        onHide={() => {
          setShowDeleteCurrentExecutionModal(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Execution Scheduler Confirmation
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex gap-2">
            <span>
              If you change the scheduler, all the existing executions will be
              deleted. Are you sure you want to proceed?
            </span>
          </div>
        </Modal.Body>
        <div className="col-12 d-flex gap-3 justify-content-center px-4 mt-3 mb-4">
          <Button
            variant="secondary"
            onClick={() => {
              setShowDeleteCurrentExecutionModal(false);
            }}
          >
            No
          </Button>

          <Button
            onClick={() => {
              addExecutionScheduler();
              setShowDeleteCurrentExecutionModal(false);
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

const ExecutionSchedulerForm = ({
  campaign,
  setCampaign,
  setShowDeleteCurrentExecutionModal,
  addExecutionScheduler
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm();

  const onAddExecutionClick = () => {
    if (
      campaign.campaignExecutions.find(
        item => item.type !== ExecutionType.MANUAL
      )
    ) {
      setShowDeleteCurrentExecutionModal(true);
    } else {
      addExecutionScheduler();
    }
  };

  return (
    <>
      <Divider />
      <h5>Scheduler</h5>
      <Form.Group className="mb-3">
        <Form.Label>Start date</Form.Label>
        {campaign.scheduledStartDate && (
          <DateTimePicker
            name="startDate"
            value={campaign.scheduledStartDate}
            onChange={date => {
              if (!date) return;
              setCampaign({
                ...campaign,
                scheduledStartDate: date
              });
            }}
            className={`form-control ${
              errors[`startDate`] ? 'is-invalid' : ''
            }`}
          />
        )}
        <Form.Control.Feedback type="invalid">
          {errors[`startDate`]?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <>
        <Form.Group className="mb-3">
          <Form.Label>Recurrence pattern</Form.Label>
          <Controller
            name="recurrencePattern"
            control={control}
            rules={{ required: 'Please select recurrence pattern' }}
            defaultValue={campaign.recurrencePattern}
            render={({ field }) => (
              <Form.Select
                {...field}
                type="select"
                value={campaign.recurrencePattern}
                onChange={e => {
                  setCampaign({
                    ...campaign,
                    recurrencePattern: e.target.value
                  });
                  setValue('recurrencePattern', e.target.value);
                }}
                name="recurrencePattern"
              >
                <option value="">Select recurrence pattern</option>
                {scheduleTypes.map(option => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            )}
          />
          {errors['recurrencePattern'] && (
            <div className="invalid-feedback d-block">
              {errors['recurrencePattern'].message}
            </div>
          )}
        </Form.Group>
        <WizardInput
          type="number"
          errors={errors}
          label="Number of executions"
          name="numberOfExecutions"
          formControlProps={{
            className: 'input-spin-none mb-3',
            ...register('numberOfExecutions', {
              required: 'Please enter number of executions'
            }),
            value: campaign.numberOfExecutions,
            onChange: e => {
              setCampaign({
                ...campaign,
                numberOfExecutions: parseInt(e.target.value)
              });
            }
          }}
        />
        <Button
          onClick={() => handleSubmit(onAddExecutionClick)()}
          className="mt-2"
        >
          Add
        </Button>
      </>
    </>
  );
};

ExecutionSchedulerForm.propTypes = {
  campaign: PropTypes.object,
  setCampaign: PropTypes.func,
  setShowDeleteCurrentExecutionModal: PropTypes.func,
  addExecutionScheduler: PropTypes.func,
  isViewingCampaign: PropTypes.bool
};

ScheduleForm.propTypes = {
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setError: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  clearErrors: PropTypes.func,
  campaign: PropTypes.object,
  setCampaign: PropTypes.func,
  isUpdate: PropTypes.bool,
  emailSenders: PropTypes.array,
  textSenders: PropTypes.array,
  templates: PropTypes.array,
  segments: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  step: PropTypes.number,
  setExecutionError: PropTypes.func,
  isViewingCampaign: PropTypes.bool
};

export default ScheduleForm;
