import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import SegmentForm from 'components/customer-segments/segment';
import Divider from 'components/common/Divider';
import IconButton from 'components/common/IconButton';
import classNames from 'classnames';
import dayjs from 'dayjs';

const AddCustomerSegmentWithAIModal = ({
  fields,
  showAddCustomerSegmentWithAIModal,
  setShowAddCustomerSegmentWithAIModal,
  fetchCustomerSegments
}) => {
  const {
    reset,
    unregister,
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);

  const [criteriaError, setCriteriaError] = useState('');
  const [segments, setSegments] = useState([]);

  const [step, setStep] = useState(1);

  const fetchContacts = async (step, segments, segment) => {
    setLoadingContacts(true);

    try {
      const response = await axiosClient.get(
        `/contacts?criteria=${JSON.stringify(segment.criteria)}`
      );
      setLoadingContacts(false);
      console.log(segments);
      const newSegments = [...segments];
      newSegments[step - 1].contacts = response.data.contacts;
      setSegments(newSegments);
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get contacts');
      }
      setLoadingContacts(false);
    }
  };

  const fetchSegmentsWithAI = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        '/customer-segments/generate-with-ai'
      );
      setSegments(response.data);
      fetchContacts(step, response.data, response.data[0]);
      setLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get segments');
      }
      setLoading(false);
    }
  };

  const onSubmitData = async () => {
    const currentSegment = segments[step - 1];
    if (currentSegment.criteria.length === 0) {
      setCriteriaError('Please add at least one criteria');
      return;
    } else {
      setCriteriaError('');
    }
    if (step < segments.length) {
      setStep(step + 1);
      fetchContacts(step + 1, segments, segments[step]);
    } else {
      setLoading(true);
      try {
        await axiosClient.post('/customer-segments/multiple', {
          segments: segments
        });
        fetchCustomerSegments();
        toast.success('Customer segments added successfully');
        setLoading(false);
        setShowAddCustomerSegmentWithAIModal(false);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (showAddCustomerSegmentWithAIModal) {
      fetchSegmentsWithAI();
      setStep(1);
    }
  }, [showAddCustomerSegmentWithAIModal]);

  useEffect(() => {
    if (segments.length > 0) {
      for (let i = 0; i < segments.length + 1; i++) {
        unregister(`segmentName-${i}`);
      }
      segments.forEach((segment, index) => {
        setValue(`segmentName-${index}`, segment.segmentName);
      });
    }
  }, [segments.length]);

  return (
    <>
      <Modal
        show={showAddCustomerSegmentWithAIModal}
        onHide={() => {
          setShowAddCustomerSegmentWithAIModal(false);
          reset();
          setCriteriaError('');
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <div>
            <Modal.Title id="contained-modal-title-vcenter">
              Review and Manage AI-Generated Customer Segment
            </Modal.Title>
            <p style={{ fontSize: 'small', marginTop: '5px' }}>
              You can review the segment below and decide to add, save, or
              remove it.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: '90vh', overflowY: 'auto' }}
          className="p-0"
        >
          <Form onSubmit={handleSubmit(onSubmitData)}>
            {loading ? (
              <Row className="justify-content-center mb-3 g-3">
                <Col lg={4} className="d-flex justify-content-center">
                  <Lottie
                    animationData={infiniteLoop}
                    loop={true}
                    style={{ height: '120px', width: '120px' }}
                  />
                </Col>
                <h3 className="text-center">Loading...</h3>
              </Row>
            ) : (
              <div className="p-4">
                {segments.length > 0 &&
                  segments.map(
                    (segment, index) =>
                      step === index + 1 && (
                        <div key={index}>
                          <WizardInput
                            label="Segment Name"
                            name={`segmentName-${index}`}
                            errors={errors}
                            formGroupProps={{ className: 'mb-3' }}
                            formControlProps={{
                              ...register(`segmentName-${index}`, {
                                required: 'Segment name is required'
                              }),
                              onChange: e => {
                                const newSegments = [...segments];
                                newSegments[index].segmentName = e.target.value;
                                setSegments(newSegments);
                                clearErrors(`segmentName-${index}`);
                              },
                              value: segment.segmentName
                            }}
                          />

                          <SegmentForm
                            index={index}
                            fields={fields}
                            criteria={segment.criteria}
                            onCriteriaChange={data => {
                              const newSegments = segments.map((seg, i) =>
                                i === index ? { ...seg, criteria: data } : seg
                              );
                              setSegments(newSegments);
                              setCriteriaError('');
                              if (data && data.length > 0)
                                fetchContacts(step, newSegments, {
                                  ...segment,
                                  criteria: data
                                });
                            }}
                            loadingContacts={loadingContacts}
                            criteriaError={criteriaError}
                          />

                          <Divider className="pt-2 pb-3" />
                          <h4>Contacts meet the criteria</h4>

                          <div>
                            {loadingContacts ? (
                              <Row className="justify-content-center mb-3 g-3">
                                <Col
                                  lg={4}
                                  className="d-flex justify-content-center"
                                >
                                  <Lottie
                                    animationData={infiniteLoop}
                                    loop={true}
                                    style={{ height: '120px', width: '120px' }}
                                  />
                                </Col>
                                <h3 className="text-center">Loading...</h3>
                              </Row>
                            ) : (
                              <>
                                {segment.contacts &&
                                  segment.contacts.length > 0 && (
                                    <p>
                                      {segment.contacts.length} contact
                                      {segment.contacts.length > 1
                                        ? 's'
                                        : ''}{' '}
                                      found{' '}
                                    </p>
                                  )}
                                <div
                                  className="segment-table"
                                  style={{ overflowX: 'auto' }}
                                >
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th scope="col">First name</th>
                                        <th scope="col">Last name</th>
                                        <th scope="col" className="email">
                                          Email
                                        </th>
                                        <th scope="col">Phone number</th>
                                        <th scope="col">Source</th>
                                        <th scope="col" className="text-center">
                                          Text Opt-in
                                        </th>
                                        <th scope="col" className="text-center">
                                          Email Opt-in
                                        </th>
                                        <th scope="col">Last contacted date</th>
                                        <th scope="col">Custom fields</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {segment.contacts &&
                                      segment.contacts.length > 0 ? (
                                        segment.contacts.map(
                                          (contact, index) => (
                                            <tr key={`contact-${index}`}>
                                              <td>{contact.firstName}</td>
                                              <td>{contact.lastName}</td>
                                              <td>{contact.email}</td>
                                              <td>{contact.phoneNumber}</td>
                                              <td>{contact.source}</td>
                                              <td className="text-center">
                                                {contact.textOptIn ? (
                                                  <GoCheckCircle className="text-success fs-9" />
                                                ) : (
                                                  <IoIosCloseCircleOutline className="text-danger fs-8" />
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {contact.emailOptIn ? (
                                                  <GoCheckCircle className="text-success fs-9 " />
                                                ) : (
                                                  <IoIosCloseCircleOutline className="text-danger fs-8" />
                                                )}
                                              </td>
                                              <td width="80">
                                                {dayjs(
                                                  contact.lastContactedDate
                                                ).format('MM/DD/YYYY HH:mm')}
                                              </td>
                                              <td className="text-capitalize">
                                                {contact.customFields &&
                                                  Object.keys(
                                                    contact.customFields
                                                  ).map((field, index) => (
                                                    <div
                                                      key={index}
                                                      className="d-flex gap-2"
                                                    >
                                                      <span className="text-capitalize">
                                                        {field.replaceAll(
                                                          '_',
                                                          ' '
                                                        )}
                                                        :
                                                      </span>

                                                      <i>
                                                        {contact.customFields[
                                                          field
                                                        ] &&
                                                          contact.customFields[
                                                            field
                                                          ].toString()}
                                                      </i>
                                                    </div>
                                                  ))}
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan="12"
                                            className="text-center"
                                          >
                                            No contacts found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )
                  )}
              </div>
            )}
            <div className="d-flex justify-content-between bg-body-tertiary py-3 px-md-6">
              <IconButton
                variant="link"
                icon={'chevron-left'}
                iconAlign="left"
                transform="down-1 shrink-4"
                className={classNames('px-0 fw-semibold', {
                  'invisible ': step === 1
                })}
                onClick={() => {
                  setStep(step - 1);
                }}
                disabled={loadingContacts}
              >
                Prev
              </IconButton>

              <IconButton
                variant="outline-danger"
                transform="down-1 shrink-4"
                className="px-2 fw-semibold"
                onClick={() => {
                  const newSegments = segments.filter(
                    (seg, i) => i !== step - 1
                  );
                  setSegments(newSegments);
                  fetchContacts(step, newSegments, newSegments[step - 1]);
                  if (step > newSegments.length) setStep(step - 1);
                }}
                disabled={loadingContacts}
              >
                Remove
              </IconButton>

              <IconButton
                variant="primary"
                className="px-2"
                type="submit"
                icon={'chevron-right'}
                iconAlign="right"
                transform="down-1 shrink-4"
                disabled={loading}
              >
                {step === segments.length ? 'Save' : 'Next'}
              </IconButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

AddCustomerSegmentWithAIModal.propTypes = {
  fields: PropTypes.array,
  showAddCustomerSegmentWithAIModal: PropTypes.bool,
  setShowAddCustomerSegmentWithAIModal: PropTypes.func,
  fetchCustomerSegments: PropTypes.func
};

export default AddCustomerSegmentWithAIModal;
