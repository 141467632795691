import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import Background from 'components/common/Background';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import WizardInput from 'components/wizard/WizardInput';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import { useSearchParams } from 'react-router-dom';
import bgGrey from 'assets/img/icons/grey-bg.png';

const ContactFormPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const initNewContact = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    textOptIn: false,
    emailOptIn: false
  };
  const [isLoading, setIsLoading] = useState(false);
  const [newContact, setNewContact] = useState(initNewContact);
  const [businessLogo, setBusinessLogo] = useState('');

  useEffect(() => {
    const fetchBusinessLogo = async () => {
      try {
        const response = await axiosClient.get(
          `/contacts/get-business-logo?token=${token}`
        );
        setBusinessLogo(response?.data?.businessLogo);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Error fetching business logo:', error);
        }
      }
    };

    fetchBusinessLogo();
  }, [token]);

  const handleAddContact = () => {
    handleSubmit(async () => {
      setIsLoading(true);

      try {
        await axiosClient.post(
          `/contacts/add-contact-with-auth?token=${token}`,
          newContact
        );
        toast.success('Contact added successfully');
        setIsLoading(false);
        reset();
        setNewContact(initNewContact);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to add contact');
        }
        setIsLoading(false);
      }
    })();
  };

  useEffect(() => {
    reset();
    setNewContact(initNewContact);
  }, []);

  return (
    <>
      {isLoading ? (
        <Row className="justify-content-center my-3 g-3">
          <Col lg={4} className="d-flex justify-content-center">
            <Lottie
              animationData={infiniteLoop}
              loop={true}
              style={{ height: '120px', width: '120px' }}
            />
          </Col>
          <h3 className="text-center">Loading...</h3>
        </Row>
      ) : (
        <Form onSubmit={handleAddContact}>
          <div
            className="col-12 position-relative mb-2"
            style={{
              height: 200
            }}
          >
            <Background
              image={businessLogo || bgGrey}
              className="rounded-3 rounded-bottom-0 "
            />
          </div>
          <Col className="px-5 pt-3">
            {/* <h3>Contact Form</h3> */}
            <Row>
              <WizardInput
                label="First name"
                name="firstName"
                errors={errors}
                formGroupProps={{ className: 'col-6 mb-3' }}
                formControlProps={{
                  ...register('firstName', {
                    required: 'First name is required'
                  }),
                  onChange: e => {
                    setNewContact({
                      ...newContact,
                      firstName: e.target.value
                    });
                  }
                }}
              />
              <WizardInput
                label="Last name"
                name="lastName"
                errors={errors}
                formGroupProps={{ className: 'col-6 mb-3' }}
                formControlProps={{
                  ...register('lastName', {
                    required: 'Last name is required'
                  }),
                  onChange: e => {
                    setNewContact({
                      ...newContact,
                      lastName: e.target.value
                    });
                  }
                }}
              />
            </Row>

            <WizardInput
              type="email"
              errors={errors}
              label="Email*"
              name="email"
              formGroupProps={{ className: 'mb-3' }}
              formControlProps={{
                ...register('email', {
                  required: 'Email field is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Email must be valid'
                  }
                }),
                onChange: e => {
                  setNewContact({
                    ...newContact,
                    email: e.target.value
                  });
                }
              }}
            />
            <WizardInput
              type="text"
              label="Phone number"
              name="phoneNumber"
              errors={errors}
              formGroupProps={{
                className: 'mb-3'
              }}
              formControlProps={{
                className: 'input-spin-none',
                ...register('phoneNumber', {
                  required: 'Phone number is required'
                }),
                onChange: e => {
                  setNewContact({
                    ...newContact,
                    phoneNumber: e.target.value
                  });
                }
              }}
            />

            <Form.Check type="checkbox" id="textOptIn" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="textOptIn"
                checked={newContact.textOptIn}
                onChange={e =>
                  setNewContact({
                    ...newContact,
                    textOptIn: e.target.checked
                  })
                }
              />
              <Form.Check.Label className="mb-0 text-700">
                Text Opt-in
              </Form.Check.Label>
            </Form.Check>
            <Form.Check type="checkbox" id="emailOptIn" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="emailOptIn"
                checked={newContact.emailOptIn}
                onChange={e =>
                  setNewContact({
                    ...newContact,
                    emailOptIn: e.target.checked
                  })
                }
              />
              <Form.Check.Label className="mb-0 text-700">
                Email Opt-in
              </Form.Check.Label>
            </Form.Check>
            <Divider />
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="pt-4"
            >
              <Button
                disabled={isLoading}
                onClick={() => handleAddContact(false)}
                style={{ width: 'fit-content' }}
              >
                {isLoading ? `Loading...` : `Submit`}
              </Button>
            </Flex>
          </Col>
        </Form>
      )}
    </>
  );
};

export default ContactFormPage;
